import { Col, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './trendingPlans.module.scss'
import { api } from "../../../../../Utils/API";
import { DASHBOARD_TRENDING_PLANS_DOWNLOAD_REPORT, DASHBOARD_TRENDING_PLANS, GET_SERVICES } from "../../../../../Utils/GlobalConstants";
import Toast from "../../../../../GlobalComponents/Toast";
import Summary from "./LocalComponents/Summary";
import { TOAST_ERROR_ICON_COLOR } from "../../../../../Utils/ColorsConfiguration";
import { GenericDownloadEmptyToast } from "../../../../../Utils/StaticMessages/ToastMessages";

const TrendingPlans = ({ data, SaveDashboardWidget, id, widgetPositionData, Logout }) => {
  const date = new Date();                                                   // for js date time function

  const [chartData, setChartData] = useState(null)                   // for storing piechart data from api
  const [summaryPopup, setSummaryPopup] = useState(false);                   // for opening summary popup
  const [filterType, setFilterType] = useState('All Services');                  // dropdown filter filter type
  const [integrationCode, setIntegrationCode] = useState("");                  // dropdown filter filter type
  const [serviceId, setServiceId] = useState(0);                  // dropdown filter filter type
  const [isSummaryData, setIsSummaryData] = useState(false);                  // dropdown filter filter type
  const [emptyData, setEmptyData] = useState(false);                  // empty data state

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

  let i = 0;

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Header Authorization for Download CSV API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download CSV File 
  async function DownloadAction(url, name, fileType) {
    let configuration;
    if (fileType === "csv") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}?serviceId=${serviceId}&integrationCode=${integrationCode}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setToastMessage(GenericDownloadEmptyToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call trending plan data
  async function GetTrendingPlans() {
    await api.get(`${DASHBOARD_TRENDING_PLANS}?integrationCode=${integrationCode}&serviceId=${serviceId}&IsSummaryData=${false}`, config)
      .then(resp => {
        if (resp.status == 204) {
          setEmptyData(true);
        }
        // handle success
        else {
          setEmptyData(false);
          setChartData(resp.data);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  //function used for dropdown storing of serviceid and integrationcode
  async function GetAllServices(selectedServiceName) {
    await api.get(`${GET_SERVICES}`, config)
      .then(resp => {
        resp.data.map((service) => {
          if (service.name === selectedServiceName) {
            setServiceId(service.id);
            setIntegrationCode(service.integrationCode);
            setFilterType(selectedServiceName);
          }
        })
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };


  // Function to delete widget in dashboard
  async function DeleteWidget() {
    let body = [];
    widgetPositionData.map((wd) => {
      if (wd.widgetName !== "add" && wd.id !== id) {
        body.push(wd);
      }
    })
    SaveDashboardWidget(body, true);
  };

  // Define a function to handle selecting a service from the dropdown
  const handleSelectService = (selectedServiceName) => {
    if (selectedServiceName === "All Services") {
      // If "All Services" is selected, set service id to 0 and integration code to empty string
      setServiceId(0);
      setIntegrationCode("");
      setFilterType(selectedServiceName);
    } else {
      // If a specific service is selected, fetch its details
      GetAllServices(selectedServiceName);
    }
  };

  useEffect(() => {
    GetTrendingPlans();
  }, [filterType, integrationCode, serviceId, isSummaryData]);

  return (
    <>
      <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

        <Col md={6} xs={6}>
          <h3 className="mb-0">Trending Plans</h3>
        </Col>
        <Col md={6} xs={6} className="text-right">
          <span className="dashboard-arrow mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setIsSummaryData(true); }}></span>
          <span className="dashboard-download mx-1 cpointer d-none-mobile"
            onClick={() => { DownloadAction(DASHBOARD_TRENDING_PLANS_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_trending_plans.csv`, "csv") }}>
          </span>
          <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
        </Col>
        {
          summaryPopup &&
          <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} filterType={filterType} setFilterType={setFilterType} setIsSummaryData={setIsSummaryData} isSummaryData={isSummaryData} integrationCode={integrationCode} serviceId={serviceId} setServiceId={setServiceId} setIntegrationCode={setIntegrationCode} Logout={Logout} />
        }
      </Row>
      <Row className={`pt-2 ${styles["widget-header"]} `}>
        <Col md={6} xs={6}></Col>
        <Col md={6} xs={6}>
          <Form.Group >
            <NavDropdown title={filterType} id="cloud-service-dropdown-3" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
              <NavDropdown.Item eventKey="All Services"
                className={filterType === "All Services" ? "selected" : ""}
              >All Services</NavDropdown.Item>
              <NavDropdown.Item eventKey="Microsoft CSP"
                className={filterType === "Microsoft CSP" ? "selected" : ""}
              >Microsoft CSP</NavDropdown.Item>
              <NavDropdown.Item eventKey="Google Workspace"
                className={filterType === "Google Workspace" ? "selected" : ""}
              >Google Workspace</NavDropdown.Item>
            </NavDropdown>
          </Form.Group>
        </Col>
      </Row>
      {
        emptyData &&
        <div className={styles["no-data-customers"]}>
          <span className="mt-4 mb-3">No data to display</span>
        </div>
      }
      {chartData && !emptyData && <div className={`${styles["trending-plans-div"]} `}>
        {
          chartData.map((data) => {
            i++;
            return (
              <Row className={`mb-1 py-2 ${styles["trending-plans-card"]} ${styles[`trending-plans-card-${i}`]}`}>
                <Col md={1} xs={1}>
                  <p className={`mb-0 ${styles["numbers"]} `}>{i}</p>
                </Col>
                <Col md={11} xs={11}>
                  <p className="mb-0 pb-0 text-strong">{data.planName}</p>
                </Col>
              </Row>
            )

          })
        }
      </div>}
    </>
  )
}

export default TrendingPlans
