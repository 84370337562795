import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Accordion, Form, Container } from "react-bootstrap";
import styles from '../../GWSCustomerPrerequisite.module.scss';
import { domainRegex } from '../../../../../../../../GlobalComponents/RegExPatterns';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import DeleteCardImage from '../../../../../../../../Assets/Images/Illustrations/delete-card-img.svg';
import { TEXT_SECONDARY } from '../../../../../../../../Utils/ColorsConfiguration';
import { CustomerDomainError, CustomerPrerequisiteDoaminCheck } from '../../../../../../../../Utils/StaticMessages/GenericErrorMessages';
const GWSCustomerDomainCheck = (props) => {

  const navigate = useNavigate();                                                           // to use navigate function

  const [isReadPrerequisite, setIsReadPrerequisite] = useState(localStorage.getItem("read_array").includes("Prerequisite") ? true : false);
  const [accountHoldStatusPopup,setAccountHoldStatusPopup]=useState(false) 

  //function to handle the transfer button click by showing a popup if the account is on "System Hold" or "Manual Hold", otherwise enables the transfer.
  const handleTransferClick = () => {
    if (props.accountHoldStatus === "System Hold" || props.accountHoldStatus==="Manual Hold") {
      setAccountHoldStatusPopup(true); 
    } else {
      props.enableTransfer(); 
    }
  };
  return (
    <>
    {/* popup shown when reseller account status is 'System Hold' or 'Manual Hold' upon clicking of transfer button */}
        <Popup
          className="custom-modal custom-modal-sm"
          open={accountHoldStatusPopup}
          onClose={() => setAccountHoldStatusPopup(false)} 
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <div className="header p-4 py-3">
                <h2 className="mb-0 px-3">Transfer Order</h2>
                <XLg
                  size={18}
                  className="cpointer text-strong close-icon-popup-sm"
                  onClick={close}
                  color={TEXT_SECONDARY}
                />
              </div>
              <Container>
                <div className={styles["no-data-cloud-delete"]}>
                  <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg" />
                  <div className='my-3 text-center text-medium'>
                   {CustomerPrerequisiteDoaminCheck}
                  </div>
                </div>
              </Container>
            </div>
          )}
        </Popup>
      {/* Domain Check Accorion */}
      <Accordion.Item className={`mt-5 ${props.domainCheckError ? "accordionErrors" : ""}`} eventKey="domainCheck">
        <Accordion.Header>
          <h3 className={`${styles['accordion-header-wrapper']}`}>
            <label>
              {props.stepsCompletedCheck.isDomainCheckCompleted ?
                <span className="step-completed">&nbsp;</span>
                : <span className={`${styles['accordion-number']}`}>1</span>
              }
            </label>
            <label className="pl-2">
              <span className={`${styles['accordion-name']}`}>Domain Check</span>
              <span className="mt-2">Please fill the pre-requisites to proceed.</span>
            </label>
          </h3>
        </Accordion.Header>

        <Accordion.Body className='pt-0'>
          <div className={`${styles['accordion-content']}`}>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formOrganaisation">
                  <Form.Label>Enter Customer Domain Name: <span className="red">*</span></Form.Label>
                  <Form onSubmit={props.getDomainAvailability}>
                    <Row>
                      <Col lg={3} md={6} >
                        <Row className="align-items-center">
                          <Col md={12}>
                            {!props.stepsCompletedCheck.isDomainCheckCompleted &&
                              <Form.Control
                                type="text"
                                value={props.domainName}
                                disabled={isReadPrerequisite}
                                placeholder="Enter Customer Domain Name"
                                onChange={(e) => props.checkDomainAvailability(e)}
                              />
                            }

                            {props.stepsCompletedCheck.isDomainCheckCompleted &&
                              <Form.Control
                                type="text"
                                value={props.domainName}
                                placeholder={props.domainName}
                                readOnly
                                disabled
                              />
                            }
                          </Col>
                          {props.domainName.length > 0 && !domainRegex.test(props.domainName) && !props.isDomainFormatValid &&
                            <Col md={12}>
                              <span className='red text-small'>{CustomerDomainError}</span>
                            </Col>
                          }

                          {(props.isDomainAvailable.isSuccess === true && props.isDomainAvailable.isExistInSynnex === false) &&

                            <span className='d-flex items-align-center mt-1'>
                              <span className="status-success  mr-1">&nbsp;</span>
                              <span className="green text-small">
                                {props.isDomainAvailable.statusMessage}
                              </span>
                            </span>

                          }

                          {(props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistInSynnex === false) &&
                            <span className='d-flex items-align-center mt-1'>
                              <span className="status-error mr-1">&nbsp;</span>
                              <span className="red text-small">
                                {props.isDomainAvailable.statusMessage}
                              </span>
                            </span>
                          }

                          {props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistInSynnex === true &&
                            <span className='d-flex items-align-center mt-1'>
                              <span className="status-error mr-1">&nbsp;</span>
                              <span className="red text-small">
                                {props.isDomainAvailable.statusMessage}
                              </span>
                            </span>
                          }


                        </Row>
                      </Col>
                      <Col md={6}>
                        {props.isCheckingDomain &&

                          <Button
                            className="btn btn-lg ml-2 px-4"
                            variant="light"
                            disabled
                          >
                            Checking Availability...
                          </Button>

                        }
                        {!props.isCheckingDomain  && !isReadPrerequisite &&
                          <>
                            {props.btnChecks.btnCheckAvailability &&
                              <Button
                                className="btn btn-lg btn-border-light px-4"
                                variant="light"
                                onClick={props.getDomainAvailability}
                              >
                                Check Availability
                              </Button>
                            }

                            {!props.btnChecks.btnCheckAvailability &&

                              <Button
                                className="btn btn-lg ml-2 px-4"
                                variant="light"
                                disabled
                              >
                                Check Availability
                              </Button>
                            }
                          </>
                        }
                      </Col>
                    </Row>

                    <Row>
                      <Col className="text-right">
                        <div className="button-wrapper">
                          {props.isCheckingDomain ?
                            <Button
                              className="btn btn-lg btn-border-light px-4"
                              variant="light"
                             disabled
                            >
                              Clear
                            </Button> :
                            <Button
                              className="btn btn-lg btn-border-light px-4"
                              variant="light"
                              onClick={props.resetToInitStatus}
                            >
                              Clear
                            </Button>
                          }


                          {(props.isDomainAvailable.isSuccess === "" && props.isDomainAvailable.isExistInSynnex === "") &&

                            <Button
                              className="btn btn-lg ml-2 px-4"
                              variant="light"
                              disabled
                            >
                              Buy Subscription
                            </Button>
                          }


                          {(props.isDomainAvailable.isSuccess === true && props.isDomainAvailable.isExistInSynnex === false) &&
                            <Button
                              className="btn btn-lg ml-2 px-4"
                              onClick={() => {

                                navigate("/cloud-provision", {
                                  state: { domainName: props.domainName, customerId: props.customerCode, customerName: props.customerName, serviceId: props.gwsServiceId }
                                })
                              }}
                            >
                              Buy Subscription
                            </Button>

                          }

                          {props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistInSynnex === false &&

                            <>
                              {(props.isDomainAvailable.owned == true || props.isDomainAvailable.cloudIdentityId) ?
                                <Button
                                  className="btn btn-lg ml-2 px-4"
                                  onClick={handleTransferClick}
                                >
                                  Transfer
                                </Button> :
                                <Button
                                  className="btn btn-lg ml-2 px-4"
                                  variant="light"
                                  disabled
                                >
                                  Buy Subscription
                                </Button>
                              }

                            </>
                          }

                          {props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistInSynnex === true &&
                            <Button
                              className="btn btn-lg ml-2 px-4"
                              variant="light"
                              disabled
                            >
                              Buy Subscription
                            </Button>
                          }

                        </div>
                      </Col>
                    </Row>

                  </Form>
                </Form.Group>
              </Col>
            </Row>

          </div>
        </Accordion.Body>
      </Accordion.Item>
      {
        props.domainCheckError &&
        <p className='red text-small mt-3'>Note: {props.domainCheckError}</p>

      }
    </>
  );
}

export default GWSCustomerDomainCheck;