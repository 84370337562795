import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Accordion, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';
import './styles.scss';
import { alphanumericRegex } from '../../../../../../../../../GlobalComponents/RegExPatterns';

const DomainCheck = (props) => {


    const newCustomerOnBoard = "New Customer (Tenant) Creation";
    const transitionDomain = "Existing Customer (Tenant) Onboarding";
    const tenantURLGuide = "https://learn.microsoft.com/en-us/entra/fundamentals/how-to-find-tenant";
    const tenantIdInfoMessage = "Click here to know how to find your Microsoft tenant ID?";
    const [isReadOnlyPrerequisite, setIsReadOnlyPrerequisite] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Prerequisite") ? true : false);


    useEffect(() => {
        if (props.selectDomainCheckOption == newCustomerOnBoard) {
            if (alphanumericRegex.test(props.domainName)) {
                props.setDomainError(null);
            } else {
                props.setDomainError("Domain names can only contain letters and numbers.");
            }
        } else {
            // props.setDomainError(null);
        }


    }, [props.selectDomainCheckOption]);

    return (
        <>
            {/* Domain Check */}
            <Accordion.Item className={`mt-5 ${props.domainCheckBlockErrors ? "accordionErrors" : ""}`} eventKey="domainCheck">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        <label>
                            {props.stepsCompletedCheck.isDomainCheckCompleted ?
                                <span className="step-completed">&nbsp;</span>
                                : <span className={`${styles['accordion-number']}`}>1</span>
                            }
                        </label>
                        <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Domain Check</span>
                            {!props.stepsCompletedCheck.isDomainCheckCompleted && <span className="mt-2">Please fill the pre-requisites to proceed.</span>}
                        </label>
                    </h3>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={`${styles['accordion-content']}`}>
                        {!props.stepsCompletedCheck.isDomainCheckCompleted &&
                            <Row className='mb-3'>
                                <Col lg={8} md={12}>
                                    <>
                                        <Form.Check
                                            inline
                                            id={`inline-domain-1`}
                                            type='radio'
                                            value="New Customer"
                                            checked={props.selectDomainCheckOption === newCustomerOnBoard}
                                            name='domain'
                                            onClick={() => { props.clearDomainCheck(); props.setSelectDomainCheckOption(newCustomerOnBoard); }}
                                            label={newCustomerOnBoard}
                                        />

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip className={`${styles['csp-tooltip']}`}> A new customer creation through Synnex</Tooltip>}
                                        >
                                            <span className="infoIcon-support-table radio-button-helpers"></span>
                                        </OverlayTrigger>
                                    </>
                                    <>
                                        <Form.Check
                                            inline
                                            id={`inline-domain-2`}
                                            type='radio'
                                            value="Existing Customer (Tenant) Onboarding"
                                            checked={props.selectDomainCheckOption === transitionDomain}
                                            className='ml-5'
                                            name='domain'
                                            onClick={() => { props.clearDomainCheck(); props.setSelectDomainCheckOption(transitionDomain); }}
                                            label={transitionDomain}
                                        />

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip className={`${styles['csp-tooltip']}`}> An existing customer moving to Synnex</Tooltip>}
                                        >
                                            <span className="infoIcon-support-table radio-button-helpers"></span>
                                        </OverlayTrigger>
                                    </>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={8} md={12}>
                                <Form.Group className="mb-3" controlId="formOrganaisation">
                                    {props.selectDomainCheckOption === newCustomerOnBoard ?
                                        <Form.Label>Enter Customer Domain Name: <span className="red">*</span></Form.Label>
                                        : <Form.Label>Enter Microsoft ID (<a href={tenantURLGuide} title={tenantIdInfoMessage} target="_blank">Tenant ID</a>): <span className="red">*</span></Form.Label>
                                    }
                                    <Form onSubmit={props.selectDomainCheckOption === newCustomerOnBoard ? props.getDomainAvailability : props.getTenantAvailability}>
                                        <Row className='mx-0'>

                                            <Col md={6} >
                                                <Row className={`align-items-center domain-block ${(props.isCheckingDomain || props.domainChecked) ? "disabled-box" : ""}`}>
                                                    <Col className='px-0'>
                                                        {!props.stepsCompletedCheck.isDomainCheckCompleted && (
                                                            <Form.Control
                                                                type="text"
                                                                value={props.selectDomainCheckOption === transitionDomain ? props.microsoftId : props.domainName}
                                                                placeholder={props.selectDomainCheckOption === newCustomerOnBoard ? "Enter Customer Domain Name" : "Enter Microsoft ID (Tenant ID)"}
                                                                onChange={(e) => props.checkDomainAvailability(e)}
                                                                disabled={props.isCheckingDomain || props.domainChecked}
                                                            />
                                                        )}

                                                        {props.stepsCompletedCheck.isDomainCheckCompleted && (
                                                            <Form.Control
                                                                type="text"
                                                                value={props.selectDomainCheckOption === transitionDomain ? props.microsoftId : props.domainName}
                                                                disabled
                                                            />
                                                        )}

                                                    </Col>

                                                    {
                                                        props.selectDomainCheckOption === newCustomerOnBoard ?
                                                            <Col md={4} className='text-right domain-extension'>
                                                                <>.onmicrosoft.com</>
                                                            </Col> : ""
                                                    }
                                                </Row>

                                                {/* Check valid domain format */}
                                                <Row>
                                                    {(props.domainError) &&
                                                        <span className='d-flex items-align-center mt-1 px-0'>
                                                            <span className="status-error mr-1">&nbsp;</span>
                                                            <span className="red text-small">
                                                                {props.domainError}
                                                            </span>
                                                        </span>
                                                    }
                                                </Row>

                                                <Row>

                                                    {(props.domainCheckFE.isValid === false) &&
                                                        <span className='d-flex items-align-center mt-1 px-0'>
                                                            <span className="status-error mr-1">&nbsp;</span>
                                                            <span className="red text-small">
                                                                {props.domainCheckFE.message}
                                                            </span>
                                                        </span>
                                                    }


                                                    {
                                                        props.selectDomainCheckOption === newCustomerOnBoard &&
                                                        <>
                                                            {
                                                                props.isDomainAvailable.isSuccess === true &&
                                                                <span className='d-flex items-align-center mt-1 px-0'>
                                                                    <span className="status-success mr-1">&nbsp;</span>
                                                                    <span className="green text-small">
                                                                        {props.isDomainAvailable.message}
                                                                    </span>
                                                                </span>
                                                            }

                                                            {
                                                                props.isDomainAvailable.isSuccess === false &&
                                                                <span className='d-flex items-align-center mt-1 px-0'>
                                                                    <span className="status-error mr-1">&nbsp;</span>
                                                                    <span className="red text-small">
                                                                        {props.isDomainAvailable.message}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        props.selectDomainCheckOption === transitionDomain &&
                                                        <>

                                                            {
                                                                props.isDomainAvailable.isSuccess === true &&
                                                                <span className='d-flex items-align-center mt-1 px-0'>
                                                                    <span className="status-success mr-1">&nbsp;</span>
                                                                    <span className="green text-small">
                                                                        {props.isDomainAvailable.message}
                                                                    </span>
                                                                </span>
                                                            }

                                                            {
                                                                props.isDomainAvailable.isSuccess === false &&
                                                                <span className='d-flex items-align-center mt-1 px-0'>
                                                                    <span className="status-error mr-1">&nbsp;</span>
                                                                    <span className="red text-small">
                                                                        {props.isDomainAvailable.message}
                                                                    </span>
                                                                </span>
                                                            }

                                                        </>
                                                    }
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                {props.isCheckingDomain &&

                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Checking Availability...
                                                    </Button>

                                                }
                                                {!props.isCheckingDomain &&
                                                    <>
                                                        {(props.btnChecks.btnCheckAvailability)  && !isReadOnlyPrerequisite &&
                                                            <>
                                                                {
                                                                    props.domainError ?
                                                                        <>
                                                                            {
                                                                                props.selectDomainCheckOption == newCustomerOnBoard ?
                                                                                    <Button
                                                                                        className="btn btn-lg ml-2 px-4"
                                                                                        variant="light"
                                                                                        disabled
                                                                                    >
                                                                                        Check Availability
                                                                                    </Button> : <Button
                                                                                        className="btn btn-lg btn-border-light px-4"
                                                                                        variant="light"
                                                                                        onClick={props.selectDomainCheckOption === newCustomerOnBoard ? props.getDomainAvailability : props.getTenantAvailability}
                                                                                    >
                                                                                        Check Availability
                                                                                    </Button>
                                                                            }

                                                                        </> :
                                                                        <Button
                                                                            className="btn btn-lg btn-border-light px-4"
                                                                            variant="light"
                                                                            onClick={props.selectDomainCheckOption === newCustomerOnBoard ? props.getDomainAvailability : props.getTenantAvailability}
                                                                        >
                                                                            Check Availability
                                                                        </Button>


                                                                }
                                                            </>
                                                        }

                                                        {
                                                            !props.btnChecks.btnCheckAvailability && !isReadOnlyPrerequisite &&

                                                            <Button
                                                                className="btn btn-lg ml-2 px-4"
                                                                variant="light"
                                                                disabled
                                                            >
                                                                Check Availability
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </Form>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <div className="button-wrapper">

                                    {!props.isCheckingDomain &&
                                        <>
                                            {props.btnChecks.btnClear ?
                                                <Button
                                                    className="btn btn-lg btn-border-light px-4"
                                                    variant="light"
                                                    onClick={props.clearDomainCheck}
                                                >
                                                    Clear
                                                </Button>
                                                :
                                                <Button
                                                    className="btn btn-lg px-4"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Clear
                                                </Button>

                                            }
                                        </>
                                    }

                                    {props.isCheckingDomain &&
                                        <Button
                                            className="btn btn-lg px-4"
                                            variant="light"
                                            disabled
                                        >
                                            Clear
                                        </Button>
                                    }



                                    {
                                        props.selectDomainCheckOption == transitionDomain && <>
                                            {
                                                (props.isDomainAvailable.isSuccess === true) ?
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        onClick={props.getTransitionDomain}
                                                    >
                                                        Transition
                                                    </Button> :

                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Transition
                                                    </Button>
                                            }
                                        </>

                                    }

                                    {(props.isDomainAvailable.isSuccess === true && props.isDomainAvailable.isExistsInSynnex == false) &&
                                        <>
                                            {
                                                props.selectDomainCheckOption !== transitionDomain ?
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        onClick={props.proceedDomainFlow}
                                                    >
                                                        Proceed
                                                    </Button> :
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Proceed
                                                    </Button>
                                            }
                                        </>
                                    }

                                    {
                                        ((props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistsInSynnex == true) ||
                                            ((props.isDomainAvailable.isSuccess === false && props.isDomainAvailable.isExistsInSynnex == false) &&
                                                props.selectDomainCheckOption !== transitionDomain)) &&
                                        <Button
                                            className="btn btn-lg ml-2 px-4"
                                            variant="light"
                                            disabled
                                        >
                                            Proceed
                                        </Button>
                                    }

                                    {
                                        props.selectDomainCheckOption !== transitionDomain &&
                                        <>
                                            {(props.isDomainAvailable.isSuccess === "" && props.isDomainAvailable.isExistsInSynnex == "") &&
                                                <Button
                                                    className="btn btn-lg ml-2 px-4"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Proceed
                                                </Button>
                                            }
                                        </>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Body >
            </Accordion.Item >
        </>
    );
}

export default DomainCheck;




// {
//     props.isDomainAvailable.isDomainAvailable === true &&
//     <>
//         {
//             props.selectDomainCheckOption !== transitionDomain ?
//                 <span className='d-flex items-align-center mt-1 px-0'>
//                     <span className="status-success  mr-1">&nbsp;</span>
//                     <span className="green text-small">
//                         {props.isDomainAvailable.message}
//                     </span>
//                 </span> :

//                 <span className='d-flex items-align-center mt-1 px-0'>
//                     <span className="status-error mr-1">&nbsp;</span>
//                     <span className="red text-small">
//                         {props.isDomainAvailable.message}
//                     </span>
//                 </span>
//         }
//     </>
// }