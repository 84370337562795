import React , {useState,useEffect} from 'react'
import './styles.scss';
import { Button, Col, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { DELETE_NOTIFICATION, GET_RECENT_NOTIFICATIONS, READ_NOTIFICATION } from '../../Utils/GlobalConstants';
import { api } from '../../Utils/API';
import Toast from '../Toast';
import EmptyNotification from '../../Assets/Images/Icons/no-notifications.svg';
import { NOTIFICATIONS_READ_COLOR, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR, WIDGETS_PRIMARY_COLOR } from '../../Utils/ColorsConfiguration';
import { NotificationEmptyError } from '../../Utils/StaticMessages/GenericErrorMessages';
import { NotificationDeletionErrorToast, NotificationDeletionSuccessToast, NotificationErrorToast } from '../../Utils/StaticMessages/ToastMessages';

const NotificationCentre = ({notificationData,GetRecentNotifications,setHookNotifications,emptyNotification, Logout }) => {
    const navigate = useNavigate();   // React Router v6
    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [selectedMessage, setSelectedMessage] = useState(null);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

       //   Delete Notifications
       async function DeleteNotification(notificationId) {
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(DELETE_NOTIFICATION+notificationId,null, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setToastSuccess(true);
                    setToastMessage(NotificationDeletionSuccessToast);
                    setTimeout(() => {
                        GetRecentNotifications();
                        setHookNotifications(true);
                    }, 2000);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 3000);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastError(true);
                setToastMessage(NotificationDeletionErrorToast);
                setTimeout(() => {
                    setToastError(false);
                }, 3000);

            })
    };

    //   Delete Notifications
    async function ReadNotification(notificationId) {
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(READ_NOTIFICATION+notificationId,null, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    GetRecentNotifications();
                    setHookNotifications(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastError(true);
                setToastMessage(NotificationErrorToast);
                setTimeout(() => {
                    setToastError(false);
                }, 3000);

            })
    };

    useEffect(() => {
        if(selectedMessage){
            ReadNotification(selectedMessage);
            setTimeout(() => {
                setSelectedMessage(null);
            }, 1500);
        }
        
    
    }, [selectedMessage])
    

  return (
    <div className="notification-body-main">
        <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
        <h2 className='mb-4'>Notification Centre</h2>
        <div className="notifications-body">
        {
            !emptyNotification && notificationData && notificationData.map((notification)=>{
                return(
                    <>
                        <Row className={selectedMessage==notification.notificationId ? 'notification-main-body-focus py-3' : 'py-3' }
                            onClick={()=>{
                                        if(!(notification.isRead)){
                                            setSelectedMessage(notification.notificationId);
                                        }}
                                    }>
                            <Col xs={1}>
                                {
                                    !(notification.isRead) ? 
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                    >
                                        <circle cx="4.5" cy="4.8689" r="4" fill={WIDGETS_PRIMARY_COLOR} />
                                    </svg>  : <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                    >
                                        <circle cx="4.5" cy="4.8689" r="4" fill={NOTIFICATIONS_READ_COLOR} />
                                    </svg>
                                }
                                {notification.notificationType==="COMPLETED" ? <span className="status-paid ml-1 notification-icon-card"></span> : (
                                    notification.notificationType==="FAILED" ? <span className="status-failed ml-1 notification-icon-card"></span> : (
                                        <span className="status-inprogress ml-1 notification-icon-card"></span>
                                    )
                                )}
                            </Col>
                            <Col xs={10} className='notification-centre-description'>
                                <p className={notification.isRead ? 'mb-0' : 'mb-0 text-strong'}>{notification.notificationDescription}</p>    
                                <span className='notification-created-at text-small text-strong'>{notification.createdAt}</span>
                            </Col>
                            <Col xs={1}>  
                                <XLg
                                    size={16}
                                    className="cpointer close-icon-popup"
                                    onClick={()=>{DeleteNotification(notification.notificationId)}}
                                />
                            </Col>
                        </Row>
                        <hr className='my-0 mb-0 provision-left-bar-hr' />
                    </>
                )
            })
        }
        </div>
        <hr className='my-3 mb-3 provision-left-bar-hr' />
        {!emptyNotification && notificationData && <div className="text-center">
            <Button className="mx-2 btn btn-lg" onClick={()=>{document.querySelector('.dropdown-menu.show').classList.remove('show');navigate("/notifications")}}>
                View All Notifications
            </Button>
        </div>}
        {
            !notificationData && emptyNotification && <div className="notification-centre-no-data">
            <img src={EmptyNotification} alt="Notifications Empty Logo" />
            <h3 className='text-strong mb-0'>{NotificationEmptyError}</h3>
          </div>
        }
        
    </div>
  )
}

export default NotificationCentre
