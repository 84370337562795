import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from '../../../../../../../GlobalComponents/Toast';
import styles from './ProfileGoogleWorkspaceResellerPrerequisite.module.scss';
import { api } from '../../../../../../../Utils/API';
import { GOOGLE_VERIFY_DOMAIN, GOOGLE_GET_RESELLER_PREQUISITES, UPDATE_PARTENER_ADVANTAGE_STAGE2, GET_PARTENER_LINK_ACCEPTANCE_STATUS, POST_ENABLE_GOOGLE_SERVICE, GOOGLE_RESELLER_RESET } from '../../../../../../../Utils/GlobalConstants';
import { domainRegex } from '../../../../../../../GlobalComponents/RegExPatterns';
import SuccessImagePreRequisite from '../../../../../../../Assets/Images/Illustrations/SuccessImagePreRequisite.svg';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import { TOAST_ERROR_ICON_COLOR } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericServerError, ResellerGooglePrerequisteDomainName, ResellerGooglePrerequisteInform, ResellerGooglePrerequisteSubmit } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { GenericErrorMessageToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';
import { ResellerGWSPrerequisteNote } from '../../../../../../../Utils/StaticMessages/NotesDisclaimers';


const ProfileGoogleWorkspaceResellerPrerequisite = ({ tabName, Logout }) => {

  const navigate = useNavigate();                                                         // to use navigate function
  const [frontEndDomainCheck, setFrontEndDomainCheck] = useState("");                     // frontend error check while verifying domain name
  const [activeAccordion, setActiveAccordion] = useState(["0"]);                          // Default Active Accordion Id is "0"
  const [isClickedSecondStep, setIsClickedSecondStep] = useState(false);                  // To check whether click here is pressed by the user
  const [domainCheckBlockErrors, setDomainCheckBlockErrors] = useState(false);            // Block error
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);                    // Processing after calling api
  const [preRequisiteData, setPreRequisiteData] = useState(null);                         // data from get api regarding prerequisite details
  const [domainName, setDomainName] = useState("");                                       // entered domain name
  const [isVerified, setIsVerified] = useState(null);                                     // data received after verification of domain name
  const [verifiedDataId, setVerifiedDataId] = useState(null);                             // data received after verification of domain name
  const [linkState, setLinkState] = useState(null);                                       // link state status check
  const [partnerState, setPartnerState] = useState(null);                                 // partner state status check
  const [identityState, setIdentityState] = useState(null);                               // partner state status check
  const [partnerAcceptanceIsSuccess, setPartnerAcceptanceIsSuccess] = useState(false);    // success message from accept partner invite link
  const [partnerAcceptanceMessage, setPartnerAcceptanceMessage] = useState(null);         // message from  accept partner invite link
  const [updatedPartnerStateCheck, setUpdatedPartnerStateCheck] = useState(null);         // check if partner state is "active" or not
  const [verifiedMessage, setVerifiedMessage] = useState(null);                           // error and success message in domain check step
  const [isSuccessEnabledService, setIsSuccessEnabledService] = useState(false);          // if api returns success true then will enable last step
  const [firstStepError, setFirstStepError] = useState(false);                            // data received after verification of domain name
  const [isClickedThirdStep, setIsClickedThirdStep] = useState(false);                    // To check whether click here is pressed by the user in third step
  const [serverError, setServerError] = useState(false);                                  // server error check state in table during api call to load data
  const [confirmLoadingFlagReset, setConfirmLoadingFlagReset] = useState(false);          // Processing after calling api of reset button
  const [isVerifyDomainClicked, setIsVerifyDomainClicked] = useState(false);              // to check verify domain is clicked or not so that user can't erase the input field`
  const [isAlreadyConfigured, setIsAlreadyConfigured] = useState(null);                   // already gws is already configured
  const [distributorId, setDistributorId] = useState(null);                               // distributor id 

  //fourth step : checkboxes
  const [googleWorkspaceChecked, setGoogleWorkspaceChecked] = useState(true);
  const [googleWorkspaceEducationChecked, setGoogleWorkspaceEducationChecked] = useState(false);
  const [googleCloudChecked, setGoogleCloudChecked] = useState(false);
  const [editingGoogleCloudChecked, setEditingGoogleCloudChecked] = useState(false);
  const [editingGoogleWorkspaceEducationChecked, setEditingGoogleWorkspaceEducationChecked] = useState(false);
  const [googleWorkspaceEducationState, setGoogleWorkspaceEducationState] = useState("");
  const [googleCloudState, setGoogleCloudState] = useState("");
  const [googleWorkspaceState, setGoogleWorkspaceState] = useState("");

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                                         // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                        // toast message usestate

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to get details whether doamin name is already configured or not
  async function GetGooglePreRequisiteData() {
    await api.get(GOOGLE_GET_RESELLER_PREQUISITES, config)
      .then(resp => {
        if(resp.status==204){
          setIsAlreadyConfigured(false);
          setPreRequisiteData({
            "isGWAlreadyConfigured": false,
            "isGWEducationEnabled": false,
            "isGCPEnabled": false,
            "domainName": null,
            "domainState": null,
            "identityState": null,
            "partnerState": null,
            "linkState": null,
            "cloudIdentityId": null,
            "distributorId": null,
            "partnerAdvantageEnrollUri": null,
            "partnerInviteLinkUri": null,
            "gwServiceState": null,
            "gwEduServiceState": null,
            "gcpServiceState": null
        });
        }else{
        setPreRequisiteData(resp.data);
        setIsAlreadyConfigured(resp.data.isGWAlreadyConfigured);
        setIdentityState(resp.data.identityState);
        setDomainName(resp.data.domainName);
        setLinkState(resp.data.linkState);
        setGoogleWorkspaceEducationState(resp.data.gwEduServiceState);
        setGoogleCloudState(resp.data.gcpServiceState);
        setGoogleWorkspaceState(resp.data.gwServiceState);
        if (resp.data.gwServiceState === "INPROGRESS") {
          setIsSuccessEnabledService(true);
        }
        setIsClickedThirdStep(false);
        if (resp.data.linkState === "ACTIVE") { setIsClickedThirdStep(true); }
        setVerifiedDataId(resp.data.cloudIdentityId);
        setPartnerState(resp.data.partnerState);
        setGoogleWorkspaceEducationChecked(resp.data.isGWEducationEnabled);
        setGoogleCloudChecked(resp.data.isGCPEnabled);
        setDistributorId(resp.data.distributorId);
        setServerError(false);

        if ((resp.data.identityState !== "ACTIVE")) {
          setActiveAccordion(['0']);
        }
        if ((resp.data.partnerState === "INACTIVE" || resp.data.partnerState === "INPROGRESS") && resp.data.identityState === "ACTIVE") {
          setActiveAccordion(['1']);
        }
        if (resp.data.linkState === "INVITED" && resp.data.identityState === "ACTIVE" && resp.data.partnerState === "ACTIVE") {
          setActiveAccordion(['2']);
        }
        if (((resp.data.linkState === "ACTIVE" && resp.data.identityState === "ACTIVE" && resp.data.partnerState === "ACTIVE") || partnerAcceptanceIsSuccess)) {
          setActiveAccordion(['3']);
        }}
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  //reset all the fields
  async function GetResetData() {
    setConfirmLoadingFlagReset(true);
    await api.get(`${GOOGLE_RESELLER_RESET}?domainName=${domainName}`, config)

      .then(resp => {
        setConfirmLoadingFlagReset(false);
        if (resp.data.isSuccess) {
          setIsVerifyDomainClicked(false);
          setVerifiedDataId(null);
          setVerifiedMessage(null);
          setIdentityState(null);
          setIsVerified(null);
          setDomainName("");
          setActiveAccordion(["0"]);
          setIsClickedSecondStep(false);
          setIsClickedThirdStep(false);
          setUpdatedPartnerStateCheck(false);
          setDomainCheckBlockErrors(false);
          GetGooglePreRequisiteData();
        }
      })
      .catch(error => {
        setConfirmLoadingFlagReset(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  //domain availability check
  async function VerifyDomainAvailability(domainName) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${GOOGLE_VERIFY_DOMAIN}?domainName=${domainName}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setIsVerified(resp.data.isVerified);
          setVerifiedMessage(resp.data.message);
          setVerifiedDataId(resp.data.cloudIdentityId)
          setConfirmLoadingFlag(false);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setConfirmLoadingFlag(false);
        }
      });
  }

  // Update second step status check
  async function UpdatePartenetAdvantageStep2(domainName) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.put(`${UPDATE_PARTENER_ADVANTAGE_STAGE2}?domainName=${domainName}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmLoadingFlag(false);
          setActiveAccordion(["2"]);
          setUpdatedPartnerStateCheck(resp.data.isSuccess);
          GetGooglePreRequisiteData();
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      })
  }

  // Function for third step success or failure
  async function GetPartenerLinkAcceptance() {
    await api.get(`${GET_PARTENER_LINK_ACCEPTANCE_STATUS}?domainName=${domainName}`, config)
      .then(resp => {
        setPartnerAcceptanceMessage(resp.data.message);
        setDomainCheckBlockErrors(false);
        setPartnerAcceptanceIsSuccess(resp.data.isSuccess);
        if (partnerAcceptanceIsSuccess) {
          GetGooglePreRequisiteData();
          setActiveAccordion(["3"])
        }
        else {
          setDomainCheckBlockErrors(true);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  function DomainVerificationFunction(domainName) {
    setIsVerifyDomainClicked(true);
    if (frontEndDomainCheck === 'Valid') {
      VerifyDomainAvailability(domainName);
      setFirstStepError(false);
    }
    else if (frontEndDomainCheck === 'Invalid') {
      setFirstStepError(true);
    }

  }

  const checkDomainAvailability = (e) => {
    setFirstStepError(false);
    setFrontEndDomainCheck("");
    setVerifiedDataId(null);
    setVerifiedMessage(null);
    setIdentityState(null);
    setIsVerified(null);
    const value = e.target.value;
    setDomainName(value);
    if (value.length > 0) {
      if (domainRegex.test(value)) {
        setFrontEndDomainCheck('Valid');
      } else {
        setFrontEndDomainCheck('Invalid');
      }
    } else {
      setFrontEndDomainCheck("");
    }
  };

  // POST enable service.
  async function EnableGoogleService() {
    setConfirmLoadingFlag(true);
    let requestBody = {
      "domainName": domainName,
      "isGWEducationEnabled": (googleWorkspaceEducationState === "ACTIVE" || googleWorkspaceEducationState === "INPROGRESS") ? false : (googleWorkspaceEducationChecked || editingGoogleWorkspaceEducationChecked),
      "isGCPEnabled": (googleCloudState === "ACTIVE" || googleCloudState === "INPROGRESS") ? false : (googleCloudChecked || editingGoogleCloudChecked),
      "isGWEnabled": (googleWorkspaceState === "ACTIVE" || googleWorkspaceState === "INPROGRESS") ? false : true
    };
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${POST_ENABLE_GOOGLE_SERVICE}`, requestBody, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmLoadingFlag(false);
          if (resp.data.isSuccess) {
            setIsSuccessEnabledService(true);
          }
          else {
            setToastMessage(resp.data.message);
            setToastError(true);
            setTimeout(() => {
              setToastError(false);
            }, 5000);
          }
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setToastMessage(GenericErrorMessageToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }
      })
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case 'googleWorkspace':
        // Prevent changing the state of 'Google Workspace' checkbox
        setGoogleWorkspaceChecked(true);
        break;
      case 'googleWorkspaceEducation':
        setEditingGoogleWorkspaceEducationChecked(checked);
        break;
      case 'googleCloud':
        setEditingGoogleCloudChecked(checked);
        break;
      default:
        break;
    }
  };

  // To check whether it is already configured or not
  useEffect(() => {
    GetGooglePreRequisiteData();
  }, []);

  //Scroll to top on opening of page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // To check whether it is already configured or not
  useEffect(() => {
    if (tabName != "Vendor Requirements") {
      setIsSuccessEnabledService(false);
      GetGooglePreRequisiteData();
    }
  }, [tabName]);

  return (
    <>
      <>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

        {serverError &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
          </div>
        }

        {isAlreadyConfigured && !serverError && !isSuccessEnabledService &&
          <>
            <div className='ml-3 mr-3' >
              {
                <div className=' details-box'>
                  <div className='ml-4 mb-4'>
                    <Row>
                      <Col className='mt-5 mb-5'><h1 >Enabled services</h1></Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col md={5}>
                        <span> <span className='text-strong'>Google cloud public ID (CID): </span>{distributorId}-{verifiedDataId}</span>
                      </Col>
                      <Col md={7}>
                        <span><span className='text-strong'>Domain name: </span>{domainName}</span>
                      </Col>
                    </Row>
                    <div className='mt-4'>
                      <span>Enable cloud services:</span>
                      <div key="inline-checkbox" className="mb-3">
                        <Form.Check
                          className='mt-2'
                          label="Google Workspace"
                          name="googleWorkspace"
                          type='checkbox'
                          checked={googleWorkspaceChecked}
                          disabled
                        />
                        <Form.Check
                          className='mt-2'
                          label="Google Workspace for Education"
                          name="googleWorkspaceEducation"
                          type='checkbox'
                          checked={(googleWorkspaceEducationChecked ? googleWorkspaceEducationChecked : editingGoogleWorkspaceEducationChecked) || googleWorkspaceEducationState === "INPROGRESS"}
                          onChange={handleCheckboxChange}
                          disabled={googleWorkspaceEducationChecked || googleWorkspaceEducationState === "INPROGRESS"}
                        />
                        <Form.Check
                          className='mt-2'
                          label="Google Cloud"
                          name="googleCloud"
                          type='checkbox'
                          checked={(googleCloudChecked ? googleCloudChecked : editingGoogleCloudChecked) || googleCloudState === "INPROGRESS"}
                          onChange={handleCheckboxChange}
                          disabled={googleCloudChecked || googleCloudState === "INPROGRESS"}
                        />
                      </div>
                      <div className='text-small pb-3 ml-6'> To apply for Google Workspace for Education you need to go through authentication </div>

                    </div>
                    <Row className='pb-5 mr-2'>
                      <Col className="text-right">
                        <div className="button-wrapper">
                          {!(editingGoogleCloudChecked || editingGoogleWorkspaceEducationChecked) && !confirmLoadingFlag && <Button
                            className="btn btn-lg mx-2"
                            variant="light"
                            disabled
                          >
                            Clear
                          </Button>}
                          {confirmLoadingFlag ?
                            (<Button
                              className="btn btn-lg mx-2"
                              variant="light"
                              disabled
                            >
                              Clear
                            </Button>) :
                            ((editingGoogleCloudChecked || editingGoogleWorkspaceEducationChecked) && <Button
                              className="btn-lg mr-3 mx-3 btn-border-light"
                              variant="light"
                              onClick={() => { setEditingGoogleCloudChecked(false); setEditingGoogleWorkspaceEducationChecked(false); }}
                            >
                              Clear
                            </Button>)}

                          {!(editingGoogleCloudChecked || editingGoogleWorkspaceEducationChecked) && !confirmLoadingFlag &&
                            <Button
                              disabled
                              className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                              variant="light"
                            >
                              Enable
                            </Button>}
                          {confirmLoadingFlag ?
                            (<Button
                              disabled
                              className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                              variant="light"
                            >
                              Processing . . .
                            </Button>) :
                            ((editingGoogleCloudChecked || editingGoogleWorkspaceEducationChecked) &&
                              <Button
                                className="btn btn-lg ml-2 px-4"
                                onClick={() => { EnableGoogleService(); }}
                              >
                                Enable
                              </Button>)}
                        </div>
                      </Col>
                    </Row>
                  </div>

                </div>}
            </div>
          </>
        }

        {/* success screen when enabled service is saved */}

        {(isSuccessEnabledService || googleWorkspaceState === "INPROGRESS") &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={SuccessImagePreRequisite} className="empty-cloud-invoice-table-svg" />
            <span className='mt-4 mb-1'>{ResellerGooglePrerequisteSubmit}</span>
            <span className='mb-4'> {ResellerGooglePrerequisteInform}</span>
            <Button className='pl-10 pr-10' onClick={() => navigate('/cloud-provision')}>View Plans</Button>
          </div>}

        {/* Loading State */}
        {isAlreadyConfigured === null && !serverError &&
          <div className={styles["no-data-cloud-invoice"]}>
            <div className="management-console-loading-component text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
          </div>
        }

        {/* when gw is not configured */}
        {!isAlreadyConfigured && isAlreadyConfigured != null && !serverError &&
          <div className="main-content mb-4">

            <Container fluid>
              {
                !isSuccessEnabledService && googleWorkspaceState !== "INPROGRESS" &&
                <div className="accordion-panel">
                  <Accordion
                    className="pre-requisite-panels"
                    activeKey={activeAccordion}
                    onSelect={(e) => { setActiveAccordion(e); }}>
                    <Accordion.Item className={`mt-5`} eventKey="0">
                      <Accordion.Header>
                        <h3 className={`${styles['accordion-header-wrapper']}`}>
                          <label>
                            {identityState === "ACTIVE" ?
                              <span className="step-completed">&nbsp;</span>
                              : <span className={`${styles['accordion-number']}`}>1</span>
                            }
                          </label>
                          <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Cloud Identity Domain</span>
                          </label>
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body className='pt-0'>
                        <div className={`${styles['accordion-content']}`}>
                          <Row>
                            <Col md={4}>
                              <Form.Group className="mb-3" controlId="formOrganaisation">
                                <Form.Label>Enter domain name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={domainName}
                                  defaultValue={domainName}
                                  readOnly={identityState === "ACTIVE" || isVerified || isVerifyDomainClicked}
                                  placeholder="Enter domain Name"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // to check if the input starts with space
                                    const regex = /^\s/;
                                    if (regex.test(inputValue)) {
                                      // If input does not start with space, prevent further input
                                      e.preventDefault();
                                    } else {
                                      checkDomainAvailability(e);
                                    }
                                  }}
                                /> </Form.Group>

                              {/* frontend error check validation */}
                              {firstStepError &&
                                <span className='d-flex items-align-center mt-1'>
                                  <span className="status-error mr-1">&nbsp;</span>
                                  <span className="red text-small"> {ResellerGooglePrerequisteDomainName}</span>
                                </span>}
                              {verifiedMessage !== null &&
                                <>
                                  {domainName && domainName.length > 0 && isVerified && <span className='d-flex items-align-center mt-1 mb-0'>
                                    <span className="status-success  mr-1">&nbsp;</span>
                                    <span className="green text-small">
                                      {verifiedMessage}</span>
                                  </span>}
                                  {domainName && domainName.length > 0 && isVerified === false && <span className='d-flex items-align-center'>
                                    <span className="status-error mr-1">&nbsp;</span>
                                    <span className="red text-small">{verifiedMessage}</span>
                                  </span>}
                                </>}
                              {/* preventing step 1 from jerk spacing */}
                              {
                                domainName === null && verifiedMessage === null &&
                                <p className='empty-paragraph mt-3'></p>
                              }
                              {
                                isVerified === null && domainName === null && verifiedMessage === null &&
                                <p className='empty-paragraph mt-3'></p>
                              }
                              {
                                domainName !== null && identityState !== "ACTIVE" && verifiedMessage === null &&
                                <p className='empty-paragraph mt-3'></p>
                              }

                              {
                                domainName !== null && identityState === "ACTIVE" && verifiedMessage === null &&
                                <p className='empty-paragraph mt-3'></p>
                              }
                              {
                                identityState === null && verifiedMessage === null && domainName !== null &&
                                <p className='empty-paragraph mt-3'></p>
                              }
                              {
                                verifiedMessage !== null && !isVerified &&
                                <p className='empty-paragraph mt-3'></p>
                              } </Col>
                            <Col md={8} className='mt-7'>
                              {!confirmLoadingFlag &&
                                <>
                                  {(domainName && domainName.length > 0 && !isVerified && identityState !== "ACTIVE") ? <Button
                                    className="btn btn-lg btn-border-light px-4 pl-13 pr-13"
                                    variant="light"
                                    onClick={() => { DomainVerificationFunction(domainName) }}
                                  >
                                    Verify Domain
                                  </Button>
                                    :
                                    <Button
                                      className="btn btn-lg btn-border-light px-4 pl-13 pr-13"
                                      variant='light'
                                      disabled
                                    >
                                      Verify Domain
                                    </Button>}
                                  {/* for reset functionality */}
                                  {(linkState !== "ACTIVE" && (identityState === "ACTIVE" || isVerified)) && !confirmLoadingFlagReset &&
                                    <Button
                                      className="btn btn-lg btn-border-light px-4 mx-3 pl-13 pr-13"
                                      variant="light"
                                      onClick={() => { GetResetData(); }}
                                    >
                                      Reset
                                    </Button>}
                                  {
                                    confirmLoadingFlagReset &&
                                    <Button
                                      className="btn btn-lg btn-border-light px-4 mx-3 pl-13 pr-13"
                                      variant="light"
                                      disabled
                                    >
                                      Processing . . .
                                    </Button>
                                  }
                                </>}
                              {confirmLoadingFlag && <Button
                                disabled
                                className="pl-13 pr-13 px-4 btn-border-light "
                                variant="light"
                              >
                                Processing . . .
                              </Button>}
                            </Col>

                          </Row>
                          <Col>
                            {/* for dispalying GCID */}
                            {verifiedDataId &&
                              <div className='mb-4 mt-4 text-small'><span className='text-strong'>Google Cloud Public ID (CID): </span>{verifiedDataId}</div>
                            }
                            <span className='text-small'>If you don’t have cloud identity, please <a href="https://workspace.google.com/signup/gcpidentity/welcome#0" target="_blank" className={`cpointer text-strong ${styles['click-here']}`}>click here</a> for reseller registration of cloud identify account.</span>
                          </Col>
                          <Row>
                            <Col className="text-right">
                              <div className="button-wrapper">

                                {domainName && domainName.length > 0 && !isVerified && !confirmLoadingFlag && identityState !== "ACTIVE" &&
                                  <Button
                                    className="btn btn-lg btn-border-light px-4"
                                    variant="light"
                                    onClick={() => {
                                      setDomainName("");
                                      setFirstStepError(false);
                                      setFrontEndDomainCheck("");
                                      setVerifiedDataId(null);
                                      setVerifiedMessage(null);
                                      setIdentityState(null);
                                      setIsVerified(null);
                                      setIsVerifyDomainClicked(false);
                                    }}
                                  >
                                    Clear
                                  </Button>}

                                {(domainName === null || domainName.length === 0 || identityState === "ACTIVE") && !isVerified && !confirmLoadingFlag &&
                                  <Button
                                    className="btn btn-lg px-4"
                                    variant="light"
                                    disabled
                                  >
                                    Clear
                                  </Button>}
                                {isVerified && !confirmLoadingFlag &&
                                  <Button
                                    className="btn btn-lg px-4"
                                    variant="light"
                                    disabled
                                  >
                                    Clear
                                  </Button>
                                }
                                {confirmLoadingFlag && <Button
                                  className="btn btn-lg px-4"
                                  variant="light"
                                  disabled
                                >
                                  Clear
                                </Button>}
                                <>
                                  {(identityState === "ACTIVE" || isVerified) && !confirmLoadingFlagReset && <Button
                                    className="btn btn-lg ml-2 px-4"
                                    onClick={() => {
                                      if (identityState !== "ACTIVE") {
                                        GetGooglePreRequisiteData(); setActiveAccordion(['1']);
                                      }
                                      else { setActiveAccordion(['1']); }
                                    }}
                                  >
                                    Proceed </Button>}
                                  {(!(identityState === "ACTIVE" || isVerified) || confirmLoadingFlagReset) && <Button
                                    className="btn btn-lg ml-2 px-4"
                                    variant="light"
                                    disabled
                                  >
                                    Proceed  </Button>}
                                </> </div>
                            </Col>
                          </Row>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item className={`mt-5 ${(identityState === "ACTIVE") ? "" : "disabled"}`} eventKey="1">
                      <Accordion.Header>
                        <h3 className={`${styles['accordion-header-wrapper']}`}>
                          <label>
                            {partnerState === "ACTIVE" ?
                              <span className="step-completed">&nbsp;</span> :
                              <span className={`${styles['accordion-number']}`}>2</span>}
                          </label>
                          <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Partner Advantage Form</span>
                          </label>
                        </h3>
                      </Accordion.Header>
                      {(identityState === "ACTIVE") && <Accordion.Body className='pt-0' >
                        <div className={`${styles['accordion-content']}`}>

                          <div className='text-medium mb-4'>Please proceed if you have enrolled into google cloud’s partner advantage program?</div>
                          <div className='text-small mb-4'>If not, please <a href={preRequisiteData && preRequisiteData.partnerAdvantageEnrollUri} target="_blank" className={`cpointer text-strong ${styles['click-here']}`} onClick={() => { setIsClickedSecondStep(true); }}>Click Here</a> to enroll</div>

                          <Row>
                            <Col>
                              {partnerState === "INPROGRESS" && <div className='text-medium mb-4 notes-confirmation'>Your request is being processed please wait until it gets completed</div>}
                            </Col>
                            <Col className="text-right">
                              <div className="button-wrapper">
                                {confirmLoadingFlag ? <Button
                                  className="btn btn-lg mx-2"
                                  variant="light"
                                  disabled
                                >
                                  Back
                                </Button> :
                                  <Button
                                    className="btn-lg mr-3 mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => { setActiveAccordion(["0"]); }}
                                  >
                                    Back
                                  </Button>}

                                {!confirmLoadingFlag &&
                                  <> {<Button
                                    className="btn btn-lg ml-2 px-4"
                                    onClick={() => {
                                      if (partnerState !== "ACTIVE") {
                                        UpdatePartenetAdvantageStep2(domainName);
                                      }
                                      else { setActiveAccordion(["2"]) }
                                    }}
                                  >
                                    Proceed
                                  </Button>}
                                  </>}

                                {confirmLoadingFlag && <Button
                                  disabled
                                  className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                  variant="light"
                                >
                                  Processing . . .
                                </Button>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Accordion.Body>}
                    </Accordion.Item>

                    <Accordion.Item className={`mt-5 ${domainCheckBlockErrors ? "accordionErrors" : ""} ${((identityState === "ACTIVE" && partnerState === "ACTIVE") || updatedPartnerStateCheck) ? "" : "disabled"}`} eventKey="2">
                      <Accordion.Header>
                        <h3 className={`${styles['accordion-header-wrapper']}`}>
                          <label>
                            {linkState === "ACTIVE" ?
                              <span className="step-completed">&nbsp;</span> :
                              <span className={`${styles['accordion-number']}`}>3</span>
                            } </label>
                          <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Accept Partner Invite Link</span>
                          </label>
                        </h3>
                      </Accordion.Header>
                      {((identityState === "ACTIVE" && partnerState === "ACTIVE") || updatedPartnerStateCheck) && <Accordion.Body className='pt-0'>
                        <div className={`${styles['accordion-content']}`}>

                          <div className='text-medium mb-4'>Please accept the partner invite link by <a href={preRequisiteData && preRequisiteData.partnerInviteLinkUri} target="_blank" className={`cpointer text-strong ${styles['click-here']}`} onClick={() => { setIsClickedThirdStep(true); }}>Click Here</a></div>
                          <Row>
                            <Col>
                              {preRequisiteData && preRequisiteData.linkState === "INPROGRESS" && <div className='text-medium mb-4 notes-confirmation'>Your request is being processed please wait until it gets completed</div>}
                            </Col>
                            <Col className="text-right">
                              <div className="button-wrapper">
                                {confirmLoadingFlag ? <Button
                                  className="btn btn-lg mx-2"
                                  variant="light"
                                  disabled
                                >
                                  Back
                                </Button> :
                                  <Button
                                    className="btn-lg mr-3 mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => { setActiveAccordion(["1"]); setDomainCheckBlockErrors(false); }}
                                  >
                                    Back
                                  </Button>}

                                {!confirmLoadingFlag && <>
                                  {!isClickedThirdStep ? <Button
                                    className="btn btn-lg ml-2 px-4"
                                    variant="light"
                                    disabled
                                  >
                                    Proceed
                                  </Button> :
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => {
                                        if (linkState !== "ACTIVE") {
                                          GetPartenerLinkAcceptance(); GetGooglePreRequisiteData();
                                        }
                                        else {
                                          setActiveAccordion(["3"])
                                        }

                                      }}
                                    >
                                      Proceed
                                    </Button>}</>
                                }

                                {confirmLoadingFlag && <Button
                                  disabled
                                  className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                  variant="light"
                                >
                                  Processing . . .
                                </Button>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Accordion.Body>}
                    </Accordion.Item>
                    {
                      domainCheckBlockErrors &&
                      <span className="mt-2 red text-small">
                        {ResellerGWSPrerequisteNote}
                        {partnerAcceptanceMessage}
                      </span>
                    }

                    <Accordion.Item className={`mt-5 ${((linkState === "ACTIVE" && identityState === "ACTIVE" && partnerState === "ACTIVE") || partnerAcceptanceIsSuccess) ? "" : "disabled"}`} eventKey="3">
                      <Accordion.Header>
                        <h3 className={`${styles['accordion-header-wrapper']}`}>
                          <label>
                            <span className={`${styles['accordion-number']}`}>4</span>
                          </label>
                          <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Enable Service</span>
                          </label>
                        </h3>
                      </Accordion.Header>
                      {
                        ((linkState === "ACTIVE" && identityState === "ACTIVE" && partnerState === "ACTIVE") || partnerAcceptanceIsSuccess) &&
                        <Accordion.Body className='pt-0'>
                          <div className={`${styles['accordion-content']}`}>
                            <div className='text-medium mb-4'>Request Synnex to enable service:</div>
                            <div key="inline-checkbox" className="mb-3">
                              <Form.Check
                                inline
                                label="Google Workspace"
                                name="googleWorkspace"
                                type='checkbox'
                                checked={googleWorkspaceChecked}
                                disabled
                              />
                              <Form.Check
                                inline
                                label="Google Workspace for Education"
                                name="googleWorkspaceEducation"
                                type='checkbox'
                                checked={(googleWorkspaceEducationChecked ? googleWorkspaceEducationChecked : editingGoogleWorkspaceEducationChecked) || googleWorkspaceEducationState === "INPROGRESS"}
                                onChange={handleCheckboxChange}
                                disabled={googleWorkspaceEducationChecked || googleWorkspaceEducationState === "INPROGRESS"}
                              />
                              <Form.Check
                                inline
                                label="Google Cloud"
                                name="googleCloud"
                                type='checkbox'
                                checked={(googleCloudChecked ? googleCloudChecked : editingGoogleCloudChecked) || googleCloudState === "INPROGRESS"}
                                onChange={handleCheckboxChange}
                                disabled={googleCloudChecked || googleCloudState === "INPROGRESS"}
                              />
                            </div>
                            <div className='text-small pb-3'> To apply for Google Workspace for Education you need to go through authentication </div>
                            <Row>
                              <Col className="text-right">
                                <div className="button-wrapper">
                                  {confirmLoadingFlag ? <Button
                                    className="btn btn-lg mx-2"
                                    variant="light"
                                    disabled
                                  >
                                    Back
                                  </Button> :
                                    <Button
                                      className="btn-lg mr-3 mx-3 btn-border-light"
                                      variant="light"
                                      onClick={() => { setActiveAccordion(["2"]); }}
                                    >
                                      Back
                                    </Button>}


                                  {!confirmLoadingFlag &&
                                    !((googleWorkspaceState === "ACTIVE" || googleWorkspaceState === "INPROGRESS") &&
                                      (googleCloudState === "ACTIVE" || googleCloudState === "INPROGRESS") &&
                                      (googleWorkspaceEducationState === "ACTIVE" || googleWorkspaceEducationState === "INPROGRESS")) &&
                                    (!(googleWorkspaceState === "INACTIVE" &&
                                      googleCloudState === "INACTIVE" &&
                                      googleWorkspaceEducationState === "INACTIVE") &&
                                      (editingGoogleCloudChecked != googleCloudChecked || editingGoogleWorkspaceEducationChecked != googleWorkspaceEducationChecked)) &&

                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => { EnableGoogleService(); }}
                                    >
                                      Enable
                                    </Button>}
                                  {
                                    (!confirmLoadingFlag && (googleWorkspaceState === "INACTIVE" &&
                                      googleCloudState === "INACTIVE" &&
                                      googleWorkspaceEducationState === "INACTIVE")) &&
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => { EnableGoogleService(); }}
                                    >
                                      Enable
                                    </Button>
                                  }
                                  {!confirmLoadingFlag && !(googleWorkspaceState === "INACTIVE" &&
                                    googleCloudState === "INACTIVE" &&
                                    googleWorkspaceEducationState === "INACTIVE") &&
                                    (((googleWorkspaceState === "ACTIVE" || googleWorkspaceState === "INPROGRESS") &&
                                      (googleCloudState === "ACTIVE" || googleCloudState === "INPROGRESS") &&
                                      (googleWorkspaceEducationState === "ACTIVE" || googleWorkspaceEducationState === "INPROGRESS")) ||
                                      (editingGoogleCloudChecked == googleCloudChecked && editingGoogleWorkspaceEducationChecked == googleWorkspaceEducationChecked)) &&
                                    <Button
                                      disabled
                                      className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                      variant="light"
                                    >
                                      Enable
                                    </Button>
                                  }

                                  {confirmLoadingFlag && <Button
                                    disabled
                                    className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                  >
                                    Processing . . .
                                  </Button>}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Body>}
                    </Accordion.Item>
                  </Accordion>
                </div>
              }
            </Container >
          </div>
        }
      </>
    </>
  )
}
export default ProfileGoogleWorkspaceResellerPrerequisite
