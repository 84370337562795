import React, { useEffect, useState } from 'react'
import { api } from '../../../../../../Utils/API';
import { IBM_PRODUCT_PLANS, IBM_PRODUCT_ORDERING, ACCEPT_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../../Utils/GlobalConstants';
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import styles from './IBMProductDetail.module.scss';
import EmptyDataTableFilterSearch from '../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import SampleImage from '../../../../../../Assets/Images/Illustrations/sample-image-rectangle.svg'
import SearchDropdown from '../../../SearchDropdown';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import Toast from '../../../../../../GlobalComponents/Toast';
import { useLocation, useNavigate } from "react-router-dom";
import { CreditCardExpiryError, DirectDebitExpiryError, GenericCloudProvisionNoPaymentError, GenericCloudProvisionOrderError, GenericCloudProvisionPlanSelecter, GenericCloudProvisionRequestError } from '../../../../../../Utils/StaticMessages/GenericErrorMessages';

const IBMProductDetail = ({ productId, customerName, setSelectCustomerErrorState, customerId, setToastError, setToastMessage, setPlanSelectedFlag, isreadOnly, billingPaymentGatewayData, setBillingPaymentGatewayErrorMessage, setBillingPaymentGatewayData, billingPaymentCardExpiryFlag, billingPaymentDirecrDebitExpiryFlag, setBillingPaymentGatewayErrorPopup, Logout,proceedToProvisionFlag }) => {
    const navigate = useNavigate();                                                 // to use navigate function from react router dom
    let CSPProductDetailColumnName = ["", "Plan", "SKU ID", "Price", "Billing Term"];                            // Columns of table for Suspend Legacy plan Action
    let ReadOnlyCSPProductDetailColumnName = ["Plan", "SKU ID", "Price", "Billing Term"];                            // Columns of table for Suspend Legacy plan Action  
    const [productDetailsData, setProductDetailsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emptyData, setEmptyData] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [checkProductId, setCheckProductId] = useState(null);
    const [orderConfirmationModalOpen, setOrderConfirmationModalOpen] = useState(false);
    const [poNumber, setPoNumber] = useState(null);
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);

    const [orderConfirmationSuccess, setOrderConfirmationSuccess] = useState(false);
    const [orderConfirmation, setOrderConfirmation] = useState(null);

    const alphanumericRegex = /^[a-zA-Z0-9]{0,50}$/; // RegEx for PO Number

    const [ipAddress, setIpAddress] = useState(null);
    const userAgent = navigator.userAgent;
    const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
    const browserVersion = userAgent.match(/\((.*?)\)/);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(productId, customerId
        // , search, billingTerm, category, pageNo, pageSize, sortCol, sortDir
    ) {
        setLoading(true);
        (productId === undefined || productId === null) ? productId = "" : productId = productId;
        (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
        // (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        // (search === undefined || search === null) ? search = "" : search = search;
        // (category === undefined || category === null) ? category = "" : category = category;
        await api.get(`${IBM_PRODUCT_PLANS}?productId=${productId}&customerCode=${customerId}
      `, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {
                    setEmptyData(true);
                }
                // handle success
                else {
                    setEmptyData(false);
                    // console.log("listing:", resp.data);
                    setProductDetailsData(resp.data);
                }
            })
            .catch(error => {
                // console.log(error);
                setLoading(false);
                setEmptyData(true);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

    // Product Ordering
    async function ProductOrdering() {
        setConfirmLoadingFlag(true);
        let body = {
            "purchaseOrderNumber": poNumber ? poNumber : "",
            "customerCode": customerId,
            "planId": selectedData.planId,
            "quantity": 1
        }
        await api.post(IBM_PRODUCT_ORDERING, body, config)
            .then(resp => {
                // console.log("Response : ", resp.request.status);
                if (resp.request.status == 200) {
                    // handle success
                    // console.log("Response : ", resp);
                    setConfirmLoadingFlag(false);
                    setOrderConfirmation(resp.data);
                    setOrderConfirmationSuccess(true);
                    setCheckProductId(null);
                    setSelectedData(null);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    // console.log("Error : ", error);
                    setConfirmLoadingFlag(false);
                    setToastMessage("IBM Product Provision Failed at the moment, try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 20000);
                }
            });
    };

    function generateRandomIp() {
        // Generate random IP address components
        const octets = [];
        for (let i = 0; i < 4; i++) {
            octets.push(Math.floor(Math.random() * 256).toString());
        }

        // Join the octets with dots to form the IP address
        const ipAddress = octets.join(".");

        return ipAddress;
    }

    // Terms and Condition accept Popup Data
    async function TermsAndConditionAcceptFunction(browser, ipAddress) {
        (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
        let body = {
            "browser": browser,
            "ipAddress": ipAddress,
            "module": "Cloud",
        }
        await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS, body, config).then((resp) => {
            // handle success
            // console.log(resp.data);
            localStorage.setItem("cloud_terms_and_conditions", "true");
            setIsTermsChecked(true);
        })
            .catch((error) => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
            });
    }

    useEffect(() => {
        if (!isTermsChecked) {
            fetch("https://api.ipify.org?format=json")
                .then(response => response.json())
                .then(data => setIpAddress(data.ip));
        }
    }, [isTermsChecked])

    useEffect(() => {
        if (productId)
            GetTableData(productId, customerId)
    }, [productId, customerId]);

    useEffect(() => {
        if (customerId) {
            setSelectCustomerErrorState(false);
        }
    }, [customerId])

    useEffect(() => {
        if (selectedData !== null) {
            setPlanSelectedFlag(true);
        }
        else {
            setPlanSelectedFlag(false);
        }
    }, [selectedData])

    return (
        <>
            <Popup
                open={orderConfirmationModalOpen}
                onClose={() => { setOrderConfirmationModalOpen(false); setOrderConfirmationSuccess(false); setPoNumber(""); }}
                className={`custom-modal custom-modal-ticket-details ${orderConfirmationSuccess ? "custom-modal-md" : "custom-modal-xl"}`}
                closeOnDocumentClick={!confirmLoadingFlag}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Order Confirmation - Cloud Provision</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                            />
                        </div>
                        {!orderConfirmationSuccess && <Container fluid>
                            <Row className="pb-3 pt-5">
                                <Col lg={8}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Customer Name: <span className="text-regular">{customerName}</span></h3>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Cloud Service: <span className="text-regular">IBM</span></h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {selectedData &&
                                        <Table className={`${styles['csp-change-status-core-table']}`}
                                            responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {CSPProductDetailColumnName.map(th => {
                                                        return (<th>
                                                            {th}</th>)
                                                    })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productDetailsData &&
                                                    <tr>
                                                        <td></td>
                                                        <td className={styles['big-width']}>{productDetailsData.planName}</td>
                                                        <td >{productDetailsData.skuId}</td>
                                                        <td className={styles['big-width']}>{productDetailsData.billingTerm}</td>
                                                        <td className={styles['big-width']}>Monthly</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col>

                                    <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="w-auto d-inline ml-3"
                                        id="inputPONumber"
                                        placeholder='Add PO Number'
                                        aria-describedby="inputPONumber"
                                        value={poNumber}
                                        maxlength="50"
                                        onChange={(e) => {
                                            alphanumericRegex.test(e.target.value)
                                                ? setPoNumber(e.target.value)
                                                : console.log("")
                                        }}
                                    />
                                    <Form.Text id="poNumber" />

                                </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                                <Col md={6}>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="termsCheckBox"
                                        label=""
                                        disabled
                                        checked={isTermsChecked}
                                        className="d-inline-block w-auto"
                                    />
                                    <Form.Label className="d-inline-block  w-auto mb-0">
                                        I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => { if (!isTermsChecked) { let browser = browserName[1] + " " + browserVersion[1]; TermsAndConditionAcceptFunction(browser, ipAddress); } }}>terms & conditions</a> of Synnex cloud platform
                                    </Form.Label>

                                </Col>
                                <Col md={6}>
                                    <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                        {!confirmLoadingFlag && isTermsChecked && <>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => { setOrderConfirmationModalOpen(false); }}>Cancel</Button>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={ProductOrdering}
                                            >
                                                Confirm
                                            </Button>
                                        </>
                                        }
                                        {!confirmLoadingFlag && !isTermsChecked &&
                                            <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setOrderConfirmationModalOpen(false); }}>Cancel
                                                </Button>
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Confirm
                                                </Button>
                                            </>
                                        }
                                        {confirmLoadingFlag &&
                                            <>
                                                <Button
                                                    variant="light"
                                                    disabled
                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                >Cancel
                                                </Button>
                                                <Button
                                                    disabled
                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                    variant="light"
                                                >
                                                    Processing . . .
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>}
                        {
                            orderConfirmationSuccess &&
                            <>
                                <Container className='text-center'>
                                    <p>
                                        <div className='success-page'></div>
                                    </p>
                                    <p className='text-regular'>{orderConfirmation.message}</p>
                                    <p className='text-regular'><strong>Provision Order ID:</strong> {orderConfirmation.orderCode}</p>
                                    <div>
                                        <Button
                                            className="px-4 mx-2"
                                            onClick={() => { setOrderConfirmationModalOpen(false); setPoNumber(null) }}>
                                            Ok
                                        </Button>
                                    </div>
                                </Container>
                            </>
                        }
                    </div>

                )}
            </Popup>
            <div className={`mt-4 ${styles['productlisting-main-table']}`}>

                {emptyData &&
                    <Row className='ml-4 mt-4'>
                        <Col md={10}>
                            <span className='notes-confirmation'>You already have a softlayer cloud infrastructure account, please click on Manage service to go to service management page.</span>
                        </Col>
                        <Col md={2} >
                            <div className='btn-wrapper justify-content-end d-flex mr-2'>
                                <Button
                                    className="px-4 pt-0 pb-0 pl-3 pr-3 w-md-auto col-xs-12"
                                    onClick={() => navigate("/management-console/ibm", { state: { customerId: customerId } })}
                                >   Manage service
                                </Button></div>
                        </Col>
                    </Row>
                }
                {!emptyData && productDetailsData &&
                    <>
                        <div className={`container my-3 ${styles['ibm-component-header']}`}>
                            <p className='mb-0'>IBM Cloud Provides the following services:</p>
                            <a href="https://cloud.ibm.com/login?redirect=%2Fpricing" target='_blank' className={styles["price-calculator"]}>Price calculator</a>
                        </div>
                        <Container>
                            <Row className='mb-2'>
                                <Col>
                                    <img src={SampleImage} className="sample-image-svg" />
                                    <p className='text-small'>Virtual servers</p>
                                </Col>
                                <Col>
                                    <img src={SampleImage} className="sample-image-svg" />
                                    <p className='text-small'>Bare metal servers</p>
                                </Col>
                                <Col>
                                    <img src={SampleImage} className="sample-image-svg" />
                                    <p className='text-small'>Storage</p>
                                </Col>
                                <Col>
                                    <img src={SampleImage} className="sample-image-svg" />
                                    <p className='text-small'>Load balancers</p>
                                </Col>
                            </Row>
                        </Container>
                        <Table className={`${styles['csp-change-status-core-table']}`}
                            responsive="md">
                            <thead className="sticky-top">
                                <tr>
                                    {(isreadOnly ? ReadOnlyCSPProductDetailColumnName : CSPProductDetailColumnName).map(th => {
                                        return (<th>
                                            {th}</th>)
                                    })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productDetailsData &&
                                    <tr>
                                        <td className={isreadOnly ? "d-none" : ""}><input type='radio' className='cpointer' checked={checkProductId === (productDetailsData.planId)} id={productDetailsData.planId} onChange={() => { setCheckProductId(productDetailsData.planId); setSelectedData(productDetailsData); }} /></td>
                                        <td className={styles['big-width']}>{productDetailsData.planName}</td>
                                        <td >{productDetailsData.skuId}</td>
                                        <td className={styles['big-width']}>{productDetailsData.billingTerm}</td>
                                        <td className={styles['big-width']}>Monthly</td>
                                    </tr>
                                }
                                {
                                    !selectedData &&
                                    <div className={styles['empty-selected-data']}>
                                    </div>
                                }
                                {
                                    selectedData &&
                                    <tr className='p-0 m-0x'>
                                        <td className={`${styles['IBM-plan-selected']}`} colSpan="6">

                                            <>
                                                <div className={`table ${styles['csp-purchase-addon-count-row']} mb-0`}>
                                                    <div className={`${styles['csp-purchase-addon-count-row-inner']} mb-0`}>
                                                        <Row>
                                                            <Col md={6} className="d-flex pt-0 pb-0 align-items-center">
                                                                <p className="m-0 p-0 text-medium">{GenericCloudProvisionPlanSelecter}</p>
                                                            </Col>
                                                            <Col md={6} className='pt-0 pb-0'>
                                                                <div className="btn-wrapper justify-content-end d-flex m-0 p-0">
                                                                    <Button
                                                                        variant="light"
                                                                        className="px-4 pt-0 pb-0 btn-border-light w-md-auto col-xs-12"
                                                                        onClick={() => { setCheckProductId(null); setSelectedData(null); }}
                                                                    >
                                                                        Clear
                                                                    </Button>

                                                                    <Button
                                                                        className="px-4 pt-0 pb-0 ml-5"
                                                                        onClick={() => {
                                                                            if (billingPaymentGatewayData.isDefaultPaymentMethodValid) {
                                                                                if (billingPaymentGatewayData.accountHoldStatus === "No Hold" || proceedToProvisionFlag) {
                                                                                    if (billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag) {
                                                                                        if (billingPaymentCardExpiryFlag) {
                                                                                            setBillingPaymentGatewayErrorMessage(CreditCardExpiryError)
                                                                                            setBillingPaymentGatewayErrorPopup(true)
                                                                                        }
                                                                                        else {
                                                                                            setBillingPaymentGatewayErrorMessage(DirectDebitExpiryError)
                                                                                            setBillingPaymentGatewayErrorPopup(true)
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if (customerId) {
                                                                                            setSelectCustomerErrorState(false);
                                                                                            setOrderConfirmationModalOpen(true);
                                                                                        }
                                                                                        else {
                                                                                            setSelectCustomerErrorState(true);
                                                                                            window.scrollTo(0, 0);
                                                                                        }
                                                                                    }
                                                                                    if (billingPaymentGatewayData.accountHoldStatus === "System Hold" &&  !proceedToProvisionFlag) {
                                                                                        setBillingPaymentGatewayErrorMessage(GenericCloudProvisionOrderError)
                                                                                        setBillingPaymentGatewayErrorPopup(true)
                                                                                    }
                                                                                    if (billingPaymentGatewayData.accountHoldStatus === "Manual Hold") {
                                                                                        setBillingPaymentGatewayErrorMessage(GenericCloudProvisionRequestError)
                                                                                        setBillingPaymentGatewayErrorPopup(true)
                                                                                    }

                                                                                }
                                                                            }
                                                                            else {
                                                                                if (!(billingPaymentGatewayData.isDefaultPaymentMethodValid)) {
                                                                                    setBillingPaymentGatewayErrorMessage(GenericCloudProvisionNoPaymentError)
                                                                                    setBillingPaymentGatewayErrorPopup(true)
                                                                                }
                                                                            }

                                                                        }}
                                                                    >
                                                                        Proceed
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </>

                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </>}
            </div>
        </>
    )
}

export default IBMProductDetail
