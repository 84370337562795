import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, NavDropdown, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { DASHBOARD_TRENDING_PLANS, DASHBOARD_TRENDING_PLANS_DOWNLOAD_REPORT, GET_SERVICES } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import DataTable, { createTheme } from 'react-data-table-component';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import Toast from '../../../../../../../GlobalComponents/Toast';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { BACKGROUND_COLOR_TERTIARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericEmptyStateError, GenericServerError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { GenericDownloadEmptyToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';


const Summary = ({ Logout, setSummaryPopup, summaryPopup, serviceId, filterType, setFilterType, setServiceId, setIntegrationCode, integrationCode, setIsSummaryData }) => {

    const date = new Date();                                                   // for js date time function
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["", "Plan", "Subscriptions",                              // table headers used to make object of react data table component headers
        "Seats"];
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data


    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };


    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTrendingPlans() {
        await api.get(`${DASHBOARD_TRENDING_PLANS}?integrationCode=${integrationCode}&serviceId=${serviceId}&IsSummaryData=${true}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyCustomer(true);
                }
                // handle success
                else {
                    setEmptyCustomer(false);
                    let f = [];
                    let i = 1;
                    (resp.data).map((td) => {
                        f.push
                            (
                                {
                                    "": <p className={`mb-0 ${styles["numbers"]} `}>{i++}</p>,
                                    "Number": i,
                                    "Plan": td.planName,
                                    "Subscriptions": td.totalSubscription,
                                    "Seats": td.totalSubscriptionQuantity,
                                }
                            );
                    })
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Seats") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Subscriptions") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCustomer(true);
                }
            });
    };

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px',
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height\
                marginBottom: '0.25rem',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_TERTIARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
            },
        },
        head: {
            style: {
                marginBottom: '0.75rem',
            }

        },
    };

    const conditionalRowStyles = [
        {
            when: row => row.Number === 2,
            style: {
                backgroundColor: 'rgba(216, 236, 255, 1)',
            },
        },
        {
            when: row => row.Number === 3,
            style: {
                backgroundColor: 'rgba(216, 236, 255, 0.7)',
            },
        },
        {
            when: row => row.Number === 4,
            style: {
                backgroundColor: 'rgba(216, 236, 255, 0.5)',
            },
        },
        {
            when: row => row.Number === 5,
            style: {
                backgroundColor: 'rgba(216, 236, 255, 0.3)',
            },
        },
        {
            when: row => row.Number > 5,
            style: {
                backgroundColor: 'rgba(216, 236, 255, 0.2)',
            },
        },
    ];

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&integrationCode=${integrationCode}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };


    //function used for dropdown storing of serviceid and integrationcode
    async function GetAllServices(selectedServiceName) {
        await api.get(`${GET_SERVICES}`, config)
            .then(resp => {
                resp.data.map((service) => {
                    if (service.name === selectedServiceName) {
                        setServiceId(service.id);
                        setIntegrationCode(service.integrationCode);
                        setFilterType(selectedServiceName);
                    }
                })
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        if (selectedServiceName === "All Services") {
            // If "All Services" is selected, set service id to 0 and integration code to empty string
            setServiceId(0);
            setIntegrationCode("");
            setFilterType(selectedServiceName);
        } else {
            // If a specific service is selected, fetch its details
            GetAllServices(selectedServiceName);
        }
    };

    useEffect(() => {
        GetTrendingPlans();
    }, [filterType, integrationCode, serviceId]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setIsSummaryData(false); setSummaryPopup(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Trending Plans</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

                                <Container fluid>
                                    <Row>
                                        <Col md={9}>
                                            <h2 className='notes-confirmation'>Summary</h2>
                                        </Col>
                                        <Col md={3}> <div className='text-right'>
                                            <Button
                                                variant="light"
                                                className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                onClick={() => { DownloadAction(DASHBOARD_TRENDING_PLANS_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_trending_plans.csv`, "csv") }}>
                                                Download Report
                                            </Button>
                                        </div></Col>

                                    </Row>
                                    <div className='mt-3'>
                                        <Row>
                                            <Col md={10}></Col>
                                            <Col md={2}>
                                                <Form.Group >
                                                    <NavDropdown title={filterType} id="cloud-service-dropdown-3" className={`servcies-dropdown dropdown-specialclass ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                                                        <NavDropdown.Item eventKey="All Services"
                                                            className={filterType === "All Services" ? "selected" : ""}
                                                        >All Services</NavDropdown.Item>
                                                        <NavDropdown.Item eventKey="Microsoft CSP"
                                                            className={filterType === "Microsoft CSP" ? "selected" : ""}
                                                        >Microsoft CSP</NavDropdown.Item>
                                                        <NavDropdown.Item eventKey="Google Workspace"
                                                            className={filterType === "Google Workspace" ? "selected" : ""}
                                                        >Google Workspace</NavDropdown.Item> </NavDropdown>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['customer-table']}`}>
                                                    {serverErrorCustomer &&
                                                        <div className={styles["no-data-customers"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                        </div>
                                                    }
                                                    {emptyCustomer &&
                                                        <div className={styles["no-data-customers"]}>
                                                            <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                                        </div>
                                                    }
                                                    {
                                                        !serverErrorCustomer && !emptyCustomer &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={table}
                                                            theme="solarized"
                                                            customStyles={customStyles}
                                                            persistTableHead={false}
                                                            noDataComponent={<div className={styles["no-data-customers"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>}
                                                            width="100%"
                                                            fixedHeaderScrollHeight="60vh"
                                                            fixedHeader
                                                            conditionalRowStyles={conditionalRowStyles}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary
