import React, { useState, useEffect } from 'react'
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import { Button, Col, Container, Row, Tab } from 'react-bootstrap';
import styles from './addWidget.module.scss'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { TEXT_SECONDARY } from '../../../../../../../Utils/ColorsConfiguration';
import { WidgetsEmptyError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';

const AddWidgets = ({ addWidgetPopup, setAddWidgetPopup, masterWidget, widgetPositionData }) => {

    const [addWidgetsAvailable, setAddWidgetsAvailable] = useState(null);
    const [addWidgetsTemporaryCart, setAddWidgetsTemporaryCart] = useState([]);
    const [addWidgetsTemporaryCartBlueColor, setAddWidgetsTemporaryCartBlueColor] = useState([]);

    // Get The widgets that can be added
    function GetAddWidgetDahboard() {
        let localAddWidgets = [];
        let localMasterAddWidgets = [];
        widgetPositionData.map((widget) => {
            localMasterAddWidgets.push(widget.widget)
        })
        masterWidget.map((widget) => {
            if (!(localMasterAddWidgets.includes(widget.widgetName))) {
                localAddWidgets.push({
                    "id": widget.id,
                    "widget": widget.widgetName
                })
            }
        })
        setAddWidgetsAvailable(localAddWidgets);
    };

    // Adding widget to cart
    function AddWidgetToCart(widget) {
        let localCart = addWidgetsTemporaryCart;
        let localCartBlue = [];
        if (localCart.includes(widget)) {
            let local = [];
            localCart.map((lc) => {
                if (lc.widget !== widget.widget) {
                    local.push(lc);
                }
            })
            localCart = local;
        }
        else {
            localCart.push(widget);
        }
        localCart.map((lc) => {
            localCartBlue.push(lc.id);
        })
        setAddWidgetsTemporaryCartBlueColor(localCartBlue);
        setAddWidgetsTemporaryCart(localCart);
    };

    // Adding widget to cart
    function AddWidget() {
        let localCart = addWidgetsTemporaryCart;
        widgetPositionData.map((wp) => {
            if (wp.widget !== "add") {
                localCart.push(wp);
            }
        })
    };

    useEffect(() => {
        if (masterWidget && widgetPositionData) {
            GetAddWidgetDahboard();
        }
    }, [masterWidget, widgetPositionData])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={addWidgetPopup}
                onClose={() => { setAddWidgetPopup(false); setAddWidgetsTemporaryCart([]); setAddWidgetsTemporaryCartBlueColor([]); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Add Widgets</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 '>
                                {addWidgetsAvailable && addWidgetsAvailable.length > 0 && <Container fluid>
                                    <Row>
                                        {
                                            addWidgetsAvailable.map((widget) => {
                                                return (
                                                    <Col md={2} className={addWidgetsTemporaryCartBlueColor.includes(widget.id) ? `border-primary ${styles["widgets"]}` : styles["widgets"]} onClick={() => AddWidgetToCart(widget)}>
                                                        <span className={styles["widgets-icon-background"]}>
                                                            <span className={`${widget.widget === "Customers" ? 'add-customers' : (
                                                                    widget.widget === "New Customers" ? 'add-newcustomer' : (
                                                                        widget.widget === "Subscriptions Renewal" ? 'add-subscripnrenewal' : (
                                                                            widget.widget === "Seat utilization" ? 'add-seatutilization' : (
                                                                                widget.widget === "Subscriptions" ? 'add-subscription' : ('add-trendingplans')
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                                } cpointer`}>&nbsp;</span>
                                                        </span>
                                                        <h3 className='mb-0 py-2 pt-4 text-center'>{widget.widget}</h3>
                                                    </Col>
                                                )
                                            })

                                        }
                                    </Row>
                                    <div className="btn-wrapper text-right">
                                        <Button
                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="px-4 mx-2"
                                            onClick={() => {
                                                AddWidget();
                                            }}
                                        >Add</Button>
                                    </div>
                                </Container>}
                                {addWidgetsAvailable && addWidgetsAvailable.length === 0 &&
                                    <div className={styles["no-data-customers"]}>
                                        <img src={RaiseTicketIllustration} className="empty-cloud-invoice-table-svg" />
                                        <h2 className="mt-4 mb-3">{WidgetsEmptyError}</h2>
                                    </div>
                                }
                            </div>
                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default AddWidgets
