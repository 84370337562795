import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './activevsinactive.module.scss'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Summary from "./LocalComponents/Summary";
import { api } from "../../../../../Utils/API";
import { CUSTOMER_DOWNLOAD_CSV_SUMMARY, DASHBOARD_CUSTOMERS } from "../../../../../Utils/GlobalConstants";
import Toast from "../../../../../GlobalComponents/Toast";
import { TOAST_ERROR_ICON_COLOR, WIDGETS_LABEL_FILL_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR } from "../../../../../Utils/ColorsConfiguration";
import { GenericDownloadEmptyToast } from "../../../../../Utils/StaticMessages/ToastMessages";

const ActiveVsInactiveCustomers = ({ data, SaveDashboardWidget, id, widgetPositionData, Logout }) => {
  const date = new Date();                                                   // for js date time function

  const [pieChartData, setPieChartData] = useState([])                   // for storing piechart data from api
  const [summaryPopup, setSummaryPopup] = useState(false);                   // for opening summary popup
  const [tabName, setTabName] = useState("Summary");                         // to store tab name
  const [total, setTotal] = useState(null);                                  // total custumers to be displayed in center of piechart label

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 14,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();

    // Convert children to string
    const textContent = children.toString();
    const [labelWithCommas, numberWithCommas] = textContent.split(' ');

    // Remove commas from label
    const label = labelWithCommas.replace(/,/g, '');

    // Format number with commas
    const number = isNaN(parseInt(numberWithCommas.replace(/,/g, ''), 10)) ? '' : parseInt(numberWithCommas.replace(/,/g, ''), 10).toLocaleString();

    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        <tspan fill="grey" dy="-1.2em">{label}</tspan>
        <tspan className="text-strong text-large" fill={WIDGETS_LABEL_FILL_COLOR} x={left + width / 2} dy="1.2em">{number}</tspan>
      </StyledText>
    );
  }

  // Header Authorization for Download CSV API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download CSV File 
  async function DownloadAction(url, name, fileType) {
    let configuration;
    if (fileType === "csv") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(url, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setToastMessage(GenericDownloadEmptyToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all Customer count data
  async function GetAllCustomersCount() {
    await api.get(DASHBOARD_CUSTOMERS, config)
      .then(resp => {
        setPieChartData(resp.data);
        // Calculate the total sum
        let initialTotal = 0;
        resp.data.map((item) => {
          initialTotal += item.value;
        });
        setTotal(initialTotal);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to delete widget in dashboard
  async function DeleteWidget() {
    let body = [];
    widgetPositionData.map((wd) => {
      if (wd.widgetName !== "add" && wd.id !== id) {
        body.push(wd);
      }
    })
    SaveDashboardWidget(body, true);
  };

  //useEffect for calling get api on initial load
  useEffect(() => {
    GetAllCustomersCount();
  }, [])

  const handleEmptySpaceClick = (e) => {
          // Get all interactive elements
          const interactiveElements = e.currentTarget.querySelectorAll(
              'button, [href], input, select, textarea, .dropdown-toggle, .cpointer, .nav-link'
          );       
          for (let element of interactiveElements) {
              if (element === e.target || element.contains(e.target)) {
                  return;
              }
          }
          setSummaryPopup(true);
          setTabName("Details");
  };

  return (
    <>
      <Row className={`${styles["widget-header"]} align-items-center pt-0 pb-4`} onClick={handleEmptySpaceClick}>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

        <Col md={6} xs={6}>
          <h3 className="mb-0">Customers</h3>
        </Col>
        <Col md={6} xs={6} className="text-right">
          <span className="dashboard-arrow mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
          <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details") }}></span>
          <span className="dashboard-download mx-1 cpointer d-none-mobile" onClick={() => { DownloadAction(CUSTOMER_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_customer.csv`, "csv") }}
          ></span>
          <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
        </Col>
        {
          summaryPopup &&
          <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} Logout={Logout} />
        }
      </Row>
      <PieChart
        series={[{ data: pieChartData, innerRadius: 70, outerRadius: 84, }]}
        colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR]}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
            labelStyle: {
              fill: 'grey',
            },
          },
        }}
        height={210}
        width={400}
        margin={{ top: 0, bottom: 40, left: 80 }}
      >
        {pieChartData.length > 0 && <PieCenterLabel>Customers {pieChartData && total}</PieCenterLabel>}
      </PieChart>
    </>
  )
}

export default ActiveVsInactiveCustomers
