import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { PROVISION_DOWNLOAD_CLOUD_PRICING } from '../../../../../../../Utils/GlobalConstants';
import styles from './DownloadCloudPricing.module.scss'
import { api } from '../../../../../../../Utils/API';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import { GenericDownloadEmptyToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';

const DownloadCloudPricing = ({ downloadCloudPricingModalFlag,setDownloadCloudPricingModalFlag,serviceData,integrationListArray,setToastSuccess,setToastError,setToastMessage, Logout }) => {
    const [selectedServices, setSelectedServices] = useState(integrationListArray);

     // Header Authorization for General API's
     const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to handle "All Services" checkbox
    const handleAllServicesChange = (isChecked) => {
        setSelectedServices(isChecked ? serviceData.map((cloudService) => cloudService.id) : []);
    };
    
    // Function to update selected services
    const handleServiceChange = (id) => {
        // let value= document.getElementById(`service-${integrationCode}`).checked;
        // if(selectedServices.includes(integrationCode)){
        //     console.log("Get Element by Id",document.getElementById(`service-${integrationCode}`))
        //     document.getElementById(`service-${integrationCode}`).checked=false;  
        // }          
        setSelectedServices((prevSelectedServices) => {
            // console.log("Calc. : ", (props.id && (props.id).includes(integrationCode)), (selectedServices).includes(integrationCode))
            if (prevSelectedServices.includes(id)) {
                return prevSelectedServices.filter((code) => code !== id);
            } else {
                return [...prevSelectedServices, id];
            }

        });
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(url, name, fileType,selectedServices) {
        // console.log(String(date.getDate()).padStart(2, '0')+String(date.getMonth()).padStart(2, '0')+String(date.getFullYear()).padStart(2, '0'));
        let configuration;

        configuration = config;

        await api.get(`${url}?serviceIds=${selectedServices}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    // console.log("Response : ", resp.data);
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                    setDownloadCloudPricingModalFlag(false);
                    // // console.log(d);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                   Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };


    return (
        <div>
            <Popup
                open={downloadCloudPricingModalFlag}
                onClose={() => { setDownloadCloudPricingModalFlag(false); }}
                className="custom-modal custom-modal-md custom-modal-ticket-details"
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header">
                            <h2>Download Cloud Pricing</h2>
                            <XLg
                                size={20}
                                className="cpointer close-icon-popup"
                                onClick={close}
                            />
                        </div>
                        <Container>

                            {serviceData && 
                                <Col className="filter-column">
                                {/* <h3 className="mb-3">Cloud Service</h3> */}
                                <div>
                                    <div className="filter-options">
                                        <>
                                            {serviceData && serviceData.length > 0 && <Form.Check
                                                className="mb-2"
                                                type="checkbox"
                                                id={`service-all`}
                                                label="All Services"
                                                checked={(selectedServices.length === serviceData.length)}
                                                onChange={(e) => handleAllServicesChange(e.target.checked)}
                                            />}
                                            {serviceData && serviceData.length > 0 &&
                                                serviceData.map((cloudService) => {
                                                   if(cloudService.serviceType==="ONLINE"){ return (
                                                        <Form.Check
                                                            className="mb-2"
                                                            key={cloudService.id}
                                                            type="checkbox"
                                                            id={`service-${cloudService.id}`}
                                                            label={cloudService.name}
                                                            checked={selectedServices.includes(cloudService.id)}
                                                            onChange={() => handleServiceChange(cloudService.id)}
                                                        />
                                                    );}
                                                })}
                                        </>
                                    </div>
                                </div>
                            </Col>
                            }
                             <Row className="actions-row">
                                <Col className="d-flex justify-content-end">
                                    {selectedServices.length > 0 
                                        ? <Button
                                            className="btn btn-lg mx-3 btn-border-light"
                                            variant="light"
                                            onClick={() => {
                                                setSelectedServices([]);
                                            }}
                                        >
                                            Clear
                                        </Button> :
                                        <Button
                                            className="btn btn-lg mx-3 "
                                            variant="light"
                                            disabled
                                        >
                                            Clear
                                        </Button>}
                                    {
                                        (
                                            selectedServices.length > 0 ) ? (
                                        <Button
                                            className="btn btn-lg"
                                            variant="primary"
                                            onClick={() => {
                                                // console.log('Selected Services:', selectedServices);
                                                DownloadAction(PROVISION_DOWNLOAD_CLOUD_PRICING, "Download_Cloud_Pricing.csv", "csv",selectedServices)
                                            }}
                                        >
                                            Download
                                        </Button>
                                    ) : (
        
                                        <Button
                                            className="btn btn-lg"
                                            variant="light"
                                            disabled
                                        >
                                            Download
                                        </Button>
                                    )
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default DownloadCloudPricing
