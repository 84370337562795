import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, useAccordionButton, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './GWSTrialToPaid.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_TRIAL_TO_PAID_GET_PLANS, GOOGLE_WORKSPACE_TRIAL_TO_PAID_POST_PLANS } from '../../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import { BACKGROUND_COLOR_SECONDARY, BORDER_DATA_TABLE_BOTTOM_COLOR, TEXT_SECONDARY } from '../../../../../../../../Utils/ColorsConfiguration';
import { GenericEmptyStateError, GenericLoadingSubscriptions, GenericServerError } from '../../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { TrialToPaidSubscriptionSuccessToast } from '../../../../../../../../Utils/StaticMessages/ToastMessages';
import { GWSUpgardeNote } from '../../../../../../../../Utils/StaticMessages/NotesDisclaimers';


const GWSTrialToPaid = ({ subscriptionTableAPIData, planName, subscriptionId, trialToPaidActionPopupModalOpen, setTrialToPaidActionPopupModalOpen, Logout,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, setSubscriptionTableAPIData ,
setIsActionInProgress,
    setIsAutoRefreshEnabled }) => {

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number

    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
    const [loading, setLoading] = useState(false);
    const [defaultTrialToPaid, setDefaultTrialToPaid] = useState(null);
    const [trialToPaidSelectedData, setTrialToPaidSelectedData] = useState();
    const [confirmTrialToPaidLoadingFlag, setConfirmTrialToPaidLoadingFlag] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);
    const [poNumber, setPoNumber] = useState("");
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);
    const [emptyDataError, setEmptyDataError] = useState(false);                                                                  // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                                                        // to check server error-400
    let TrialToPaidCoreColumnName = ["", "Plan", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity"];
    let TrialToPaidConfirmationColumnName = ["Plan", "Billing Term", "Unit Price","Renewal Price (Ex GST)*", "Quantity", "Sub Total Price"];
    const [trialToPaidPlans, setTrialToPaidPlans] = useState();

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };
    //Get Trial To Paid Plans
    async function GetTrialToPaidListing(subscriptionId, planName) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (planName === undefined || planName === null) ? planName = "" : planName = planName;
        await api.get(`${GOOGLE_WORKSPACE_TRIAL_TO_PAID_GET_PLANS}?subscriptionId=${subscriptionId}&planName=${planName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setTrialToPaidPlans(resp.data.purchasableOfferDetails);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //post api for upgrade confirmation
    async function GwsTrialToPaidPost() {
        setConfirmTrialToPaidLoadingFlag(true);
        let data = {
            "offerName": trialToPaidSelectedData.billingFrequency,
            "offerSku": trialToPaidSelectedData.offerSku,
            "paymentPlan": trialToPaidSelectedData.paymentPlan,
            "planName": trialToPaidSelectedData.planName,
            "poNumber": poNumber,
            "quantity": subscriptionTableAPIData.quantity,
            "skuName": trialToPaidSelectedData.skuName,
            "subscriptionId": subscriptionId
        }
        await api.post(GOOGLE_WORKSPACE_TRIAL_TO_PAID_POST_PLANS, data, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmTrialToPaidLoadingFlag(false);
                    setServerError(false);
                    setEmptyDataError(false);
                    setTrialToPaidActionPopupModalOpen(false);
                    setToastMessage(TrialToPaidSubscriptionSuccessToast);
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                setConfirmTrialToPaidLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastMessage(error.response.data.errors[0]);
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);

            })
    };

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }
    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: `0.5px solid ${BORDER_DATA_TABLE_BOTTOM_COLOR}`,
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
                textTransform: 'capitalize',
            },
        }
    };

    useEffect(() => {
        GetTrialToPaidListing(subscriptionId, planName);
    }, [subscriptionId])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xl"
                open={trialToPaidActionPopupModalOpen}
                onClose={() => { setTrialToPaidActionPopupModalOpen(false); setDefaultTrialToPaid(null); setServerError(false); setEmptyDataError(false); setSubscriptionTableAPIData(null); }}
                modal
                closeOnDocumentClick={!(confirmTrialToPaidLoadingFlag || buttonCheckstep2)}
                nested
            >
                {(close) => (
                    <div className="modal">
                        {!subscriptionDetailIsLoadingFlag &&
                            <>
                                <div className="header">
                                    <h2>Trial To Paid</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong"
                                        onClick={() => { if (!confirmTrialToPaidLoadingFlag) close() }}
                                        color={TEXT_SECONDARY}
                                    />
                                </div>
                                <Container className={`upgrade-modal-height ${styles['modal-width']}`}>
                                    <Accordion defaultActiveKey="1">
                                        <Card className={`${styles['accordion-downgradeGWS']}`}>
                                            <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                                <Col md={7} className="mt-2 mb-2">
                                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                                </Col>
                                                <Col md={4} className="mt-2 mb-2">
                                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                                </Col>
                                                <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                            </Row>

                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                        {tableSubscriptionTable &&
                                                            <DataTable
                                                                columns={columnsSubscriptionTable}
                                                                data={tableSubscriptionTable}
                                                                theme="solarized"
                                                                customStyles={customStyles}
                                                                persistTableHead={false}
                                                                noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">{GenericLoadingSubscriptions}</p></div>}
                                                                width="100%"
                                                                fixedHeaderScrollHeight="60vh"
                                                                progressPending={loading}
                                                                progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">{GenericLoadingSubscriptions}</p></div>}
                                                                fixedHeader
                                                            />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                    <Box sx={{ width: '100%' }} className='pt-2'>
                                        {serverError &&
                                            <div className={styles["no-data-cloud-invoice"]}>
                                                <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                                <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                            </div>
                                        }
                                        {emptyDataError &&
                                            <div className={styles["no-data-cloud-invoice"]}>
                                                <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                                <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                            </div>
                                        }
                                        {!buttonCheckstep2 && <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                {!serverError && !emptyDataError &&
                                                    <div className={`table-responsive ${styles['gws-downgrade-core']}`}>
                                                        <h3 className='pl-5 pb-2 text-strong'>Select SKU</h3>
                                                        {trialToPaidPlans &&
                                                            <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                                                <thead className="sticky-top">
                                                                    <tr>
                                                                        {TrialToPaidCoreColumnName.map(th => {
                                                                            return (<th className={(th === "RRP (Inc GST)" || th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{
                                                                                th === "Billing Term" ? <>{th}
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                                    >
                                                                                        <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                                    </OverlayTrigger></> : th}</th>)
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        trialToPaidPlans.map((td) => {
                                                                            return (<tr>
                                                                                <td><input type='radio' className='cpointer' id={td.offerSku} checked={defaultTrialToPaid === (td.offerSku)} onChange={() => { setDefaultTrialToPaid(td.offerSku); setTrialToPaidSelectedData(td); }} /></td>
                                                                                <td>{td.planName}</td>
                                                                                <td>{td.billingCommitmentTerm}</td>
                                                                                <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                                <td className='text-right'>${td.price}</td>
                                                                                <td className='text-right'>{subscriptionTableAPIData && subscriptionTableAPIData.quantity}</td>
                                                                            </tr>)
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        }
                                                        {!trialToPaidPlans &&
                                                            <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>
                                                        }
                                                    </div>}
                                            </Typography>
                                            {/* buttons for downgrade */}
                                            {!serverError && !emptyDataError &&
                                                <div className='text-right pt-4'>
                                                    <React.Fragment>
                                                        {defaultTrialToPaid && <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light" onClick={() => { setDefaultTrialToPaid(null) }} sx={{ mr: 1 }}>
                                                            Clear
                                                        </Button>}
                                                        {!defaultTrialToPaid && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled>
                                                            Clear
                                                        </Button>}
                                                        {!defaultTrialToPaid && <Button
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled
                                                        >
                                                            Proceed
                                                        </Button>}
                                                        {defaultTrialToPaid && <Button
                                                            onClick={() => setButtonCheckstep2(true)}
                                                        >
                                                            Proceed
                                                        </Button>}
                                                    </React.Fragment>
                                                </div>}
                                        </React.Fragment>}
                                        {buttonCheckstep2 && <div className={`${styles['gws-edit-quantity-table']}`}>
                                            <h3 className='mb-4 mt-2'>Order Confirmation</h3>
                                            {trialToPaidPlans &&
                                                <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                                    <thead className="sticky-top">
                                                        <tr>
                                                            {TrialToPaidConfirmationColumnName.map(th => {
                                                                return (<th className={(th === "Unit Price" || th === "Sub Total Price" || th === "Quantity" || th === "Renewal Price (Ex GST)*") ? "text-right" : ""}>{
                                                                    th === "Billing Term" ? <>{th}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                        >
                                                                            <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                        </OverlayTrigger></> : th}</th>)
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trialToPaidSelectedData &&
                                                            <tr>
                                                                <td>{trialToPaidSelectedData.planName}</td>
                                                                <td>{trialToPaidSelectedData.billingCommitmentTerm}</td>
                                                                <td className='text-right'>${trialToPaidSelectedData.price}</td>
                                                                <td className='text-right'>${trialToPaidSelectedData.rrpExclusiveTax}</td>
                                                                <td className='text-right'>{subscriptionTableAPIData && subscriptionTableAPIData.quantity}</td>
                                                                <td className='text-right'>${((trialToPaidSelectedData.price) * (subscriptionTableAPIData && subscriptionTableAPIData.quantity)).toFixed(2)}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            }
                                             <p className='pt-1 pb-3'><span className='text-strong'>Note* :</span> {GWSUpgardeNote}</p>

                                            <Row>
                                                <Col>
                                                    <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="w-auto d-inline ml-3"
                                                        id="inputPONumber"
                                                        aria-describedby="inputPONumber"
                                                        placeholder='Add PO Number'
                                                        value={poNumber}
                                                        maxlength="50"
                                                        onChange={(e) => {
                                                            alphanumericRegex.test(e.target.value)
                                                                ? setPoNumber(e.target.value)
                                                                : console.log("")
                                                        }}
                                                    />
                                                    <Form.Text id="poNumber" />

                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={6}>
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id="termsCheckBox"
                                                        label=""
                                                        disabled
                                                        checked={isTermsChecked}
                                                        className="d-inline-block w-auto"
                                                    />
                                                    <p className="d-inline-block  w-auto mb-0">
                                                        I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                                    </p>

                                                </Col>
                                                <Col md={6}>
                                                    <div className="btn-wrapper text-right d-md-block d-none">
                                                        {isTermsChecked && <>
                                                            {!confirmTrialToPaidLoadingFlag && <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {

                                                                    setPoNumber("");
                                                                    setButtonCheckstep2(false);
                                                                }}>Back</Button>}
                                                            {confirmTrialToPaidLoadingFlag && <Button
                                                                className="px-4 mx-2"
                                                                disabled
                                                            >Back</Button>}
                                                            {!confirmTrialToPaidLoadingFlag && <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    GwsTrialToPaidPost();
                                                                }}
                                                            >
                                                                Confirm
                                                            </Button>}
                                                            {confirmTrialToPaidLoadingFlag &&
                                                                <Button
                                                                    disabled
                                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                >
                                                                    Processing . . .
                                                                </Button>}
                                                        </>
                                                        }
                                                        {!isTermsChecked &&
                                                            <>
                                                                <Button
                                                                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                    onClick={() => {
                                                                        setButtonCheckstep2(false);
                                                                    }}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Back
                                                                </Button>

                                                                <Button
                                                                    variant="light"
                                                                    className="px-4 mx-2"
                                                                    disabled
                                                                >
                                                                    Confirm
                                                                </Button>
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>}

                                    </Box>
                                </Container>
                            </>
                        }
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default GWSTrialToPaid
